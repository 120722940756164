import http from './api';

export async function profile() {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/me/',
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}