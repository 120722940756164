import {
    getFlags,
} from '../../api/feature-flags';

export function flags() {
    return async function flagsThunk(dispatch) {
        dispatch({type: 'featureFlags/loadFlags'});

        try {
            const response = await getFlags();
            dispatch({type: 'featureFlags/flagsLoaded', payload: response.data});
        } catch (e) {
            dispatch({type: 'featureFlags/loadFlagsFailed', payload: e.message});
        }
    }
}