import http from "./api";

const UploadAPI = {};

UploadAPI.GenerateUploadURL = async (params) => {
  const query = http.objectToQueryParams(params);
  const response = await http({
    method: "GET",
    baseURL: process.env.REACT_APP_MENDI_BASE_URL,
    url: `upload/?${query}`,
  });
  return response.data;
};

UploadAPI.UploadFile = async (file, url) => {
  const response = await http.put(url, file, {
    headers: {
      "Content-Type": file.type,
      "x-goog-acl": "public-read",
    },
  });

  return response.data;
};

export default UploadAPI;
