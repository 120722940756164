import http from './api';

export async function getFlags() {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/v1/feature-flags/',
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function createFlag(data) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/v1/feature-flags/',
            data,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function deleteFlag(id) {
    try {
        const result = await http({
            method: 'DELETE',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/v1/feature-flags/${id}`,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function editFlag(id, data) {
    try {
        const result = await http({
            method: 'PUT',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/v1/feature-flags/${id}`,
            data,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}
