import http from './api';

export async function getNudges(params) {
    const qp = {
        ...params,
        'order_dir': 'desc',
    }
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/nudges/',
            params: qp,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function getNudge(id) {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/nudges/${id}`,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function editNudge(id, data) {
    try {
        const result = await http({
            method: 'PUT',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/nudges/${id}`,
            data
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function createNudge(data) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/nudges/`,
            data
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function deleteNudge(id) {
    try {
        const result = await http({
            method: 'DELETE',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/nudges/${id}`,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}
