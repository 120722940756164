import {
    authenticate,
    logout,
} from '../../api/account';
import {
    profile,
} from '../../api/me';
import scheduleTokenRefresh from '../../utils/refresh-token';

export function auth(data) {
    return async function authenticateThunk(dispatch) {
        const {
            email,
            password,
        } = data;

        dispatch({type: 'auth/login'});

        try {
            const response = await authenticate(email, password);
            // Persist token values
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);

            //Schedule token refresh
            scheduleTokenRefresh();
            dispatch({type: 'auth/loggedIn', payload: response.data});
        } catch (e) {
            dispatch({type: 'auth/loginFailed', payload: e.message});
        }
    }
}

export function logoutUser() {
    return async function logoutThunk(dispatch) {
        dispatch({type: 'auth/logout'});

        try {
            await logout();
            // Remove token values
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            
            dispatch({type: 'auth/loggedOut'});
        } catch (e) {
            dispatch({type: 'auth/logoutFailed', payload: e.message});
        }
    }
}

export function loadProfile() {
    return async function loadProfileThunk(dispatch) {
        dispatch({type: 'auth/loadProfile'});

        try {
            const response = await profile();
            dispatch({type: 'auth/profileLoaded', payload: response.data});
            
            //Schedule token refresh
            scheduleTokenRefresh();
        } catch (e) {
            dispatch({type: 'auth/loadProfileFailed', payload: e.message});
        }
    }
}