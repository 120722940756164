import React, { useState } from 'react';
import {
    useParams,
    useHistory,
} from 'react-router-dom';
import {
    Container,
    Box,
    Breadcrumbs,
    Typography,
    Link,
    LinearProgress,
} from '@material-ui/core';
import PCBALogs from '../../components/PCBALogs/PCBALogs'
import FunctionalLogs from '../../components/FunctionalLogs/FunctionalLogs'
import useTranslate from '../../hooks/useTranslate';
import style from './style.module.scss';

function HwLogs() {
    const { logType } = useParams();
    const history = useHistory();
    const [logsLoading, setLogsLoading] = useState(false)
    const translate = useTranslate();
    const translationKey = logType === 'pcba' ? 'breadcrumbs_hw_logs_pcba' : 'breadcrumbs_hw_logs_functional';

    return(
        <React.Fragment>
            {logsLoading &&
                <LinearProgress />
            }
            <Container>
                <Box py={2}>
                    <Breadcrumbs>
                        <Link color="inherit" to="/" onClick={() => history.push(`/`)}>
                            <Typography color="textPrimary">{translate("breadcrumbs_home")}</Typography>
                        </Link>
                        <Typography color="textPrimary">{translate("breadcrumbs_hw_logs")}</Typography>
                        <Typography color="textPrimary">{translate(translationKey)}</Typography>
                    </Breadcrumbs>
                </Box>
            </Container>
            <Container className={style.logsContainer}>
                { logType === 'pcba' && <PCBALogs toggleLoader={setLogsLoading}/> }
                { logType === 'functional' && <FunctionalLogs toggleLoader={setLogsLoading}/> }
            </Container>
        </React.Fragment>
    )
}

export default HwLogs;