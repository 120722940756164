import http from './api';

export async function authenticate(email, password) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url:'/account/login', 
            data: {
                email,
                password,
            },
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function logout() {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/account/logout'
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function refreshToken(token) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/account/refresh-token',
            data: {
                token,
            },
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function acceptInvite({ password, token }) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/account/accept-admin-invite',
            data: {
                password,
                token,
            },
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function resetPassword({ password, token }) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/account/reset-password',
            data: {
                password,
                token,
            },
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function forgotPassword({ email }) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/account/forgot-password',
            data: {
                email,
            },
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}