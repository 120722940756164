import React, { useState, useEffect } from "react";
import {
    LinearProgress,
    Container,
    Box,
    Breadcrumbs,
    Typography,
    Link,
    Fab,
    TableContainer,
    Table,
    TableHead,
    Select,
    TableCell,
    TableRow,
    TableBody,
    TableFooter,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Button,
    Snackbar,
    InputLabel,
    MenuItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { Add, Delete } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import useTranslate from "../../hooks/useTranslate";
import style from "./style.module.scss";
import { createFirmware, deleteFirmware } from "../../api/firmwares";
import { getFirmwares, getPager } from "../../store/firmwares/selectors";
import { firmwares } from "../../store/firmwares/actionCreators";
import FileUploader from "../../components/FileUploader";

export default function Firmwares() {
    const loadFirmwaresInProgress = false;
    const history = useHistory();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const rows = useSelector(getFirmwares);
    const pager = useSelector(getPager);
    const columns = [
        { id: "version", label: translate("firmwares_table_fw_version") },
        { id: "hwID", label: translate("firmwares_table_hw_version") },
        { id: "downloadLink", label: translate("firmwares_table_download_link") },
        { id: "createdAt", label: translate("flags_table_created_at") },
        { id: "delete", label: translate("flags_table_delete"), align: "center" },
    ];
    const [createNewDialog, toggleCreateNewDialog] = useState(false);
    const [creationInProgress] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState();
    const [deleteDialog, toggleDeleteDialog] = useState(false);
    const [firmwareForDeletion, setFirmwareForDeletion] = useState(null);
    const hwVersions = [
        { id: 1, name: "r2.1a" },
        { id: 2, name: "r2.1b" },
    ];
    const hwFlavors = [
        { id: 1, name: "ty1" },
        { id: 2, name: "ub1" },
        { id: 3, name: "mu1" },
    ];

    useEffect(() => {
        dispatch(firmwares());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateUploadedFiles = (file) => {
        setUploadedFiles({ ...uploadedFiles, [file.name]: file.value });
    };
    const createFormik = useFormik({
        initialValues: {
            majorVersion: 1,
            minorVersion: 0,
            revisionVersion: 0,
            hwVersion: "",
            hwFlavor: "",
        },
        validationSchema: Yup.object({
            majorVersion: Yup.number().required(translate("validation_required")),
            minorVersion: Yup.number().required(translate("validation_required")),
            revisionVersion: Yup.number().required(translate("validation_required")),
            hwVersion: Yup.string().required(translate("validation_required")),
            hwFlavor: Yup.string().required(translate("validation_required")),
        }),
        onSubmit: async (values) => {
            try {
                if (
                    uploadedFiles !== undefined &&
                    uploadedFiles.file &&
                    uploadedFiles.file.length > 0
                ) {
                    setUploadedFiles();
                } else {
                    setNotification({
                        active: true,
                        type: "error",
                        content: translate("You must select a file."),
                    });
                }

                const fwVersion = `${values.majorVersion}.${values.minorVersion}.${values.revisionVersion}`;
                const hwVersion = `${values.hwVersion}-${values.hwFlavor}`;

                const data = new FormData();
                data.append("file", uploadedFiles.file[0]);
                data.append("firmwareVersion", fwVersion);
                data.append("hardwareVersion", hwVersion);

                await createFirmware(data);
                dispatch(firmwares());
                toggleCreateNewDialog(false);
                setNotification({
                    active: true,
                    type: "success",
                    content: translate("firmwares_firmware_created"),
                });
            } catch (e) {
                setNotification({
                    active: true,
                    type: "error",
                    content: translate(e.message),
                });
            }
        },
    });
    const [notification, setNotification] = useState({
        active: false,
        type: "",
        content: "",
    });
    const closeNotification = () => {
        setNotification({
            active: false,
        });
    };
    const fetchFirmwares = () => {
        dispatch(firmwares());
    };

    const confirmDelete = async () => {
        try {
            await deleteFirmware(firmwareForDeletion.id);
            dispatch(firmwares());
            setFirmwareForDeletion(null);
            toggleDeleteDialog(false);
            setNotification({
                active: true,
                type: "success",
                content: translate("firmwares_firmware_deleted"),
            });
        } catch (e) {
            setNotification({
                active: true,
                type: "error",
                content: translate(e.message),
            });
        }
    };

    return (
        <React.Fragment>
            {loadFirmwaresInProgress && <LinearProgress />}
            <Container>
                <Box py={2}>
                    <Breadcrumbs>
                        <Link color="inherit" to="/" onClick={() => history.push(`/`)}>
                            <Typography color="textPrimary">
                                {translate("breadcrumbs_home")}
                            </Typography>
                        </Link>
                        <Typography color="textPrimary">
                            {translate("breadcrumbs_firmwares")}
                        </Typography>
                    </Breadcrumbs>
                </Box>
            </Container>
            <Container className={style.firmwaresContainer}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align || "inherit"}
                                        >
                                            {column.label}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!rows?.length && (
                                <TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        {translate("table_empty_data_set")}
                                    </TableCell>
                                </TableRow>
                            )}
                            {rows &&
                                rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.version}</TableCell>
                                        <TableCell>{row.hwID}</TableCell>
                                        <TableCell>{row.downloadLink}</TableCell>
                                        <TableCell>{row.createdAt}</TableCell>
                                        <TableCell align="center">
                                            <Delete
                                                onClick={() => {
                                                    setFirmwareForDeletion(row);
                                                    toggleDeleteDialog(true);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan="5" style={{ borderBottom: "none" }}>
                                    {pager && (
                                        <TablePagination
                                            rowsPerPageOptions={[10, 20, 50]}
                                            component="div"
                                            count={pager.totalEntries}
                                            rowsPerPage={pager.size}
                                            page={pager.page - 1}
                                            onChangePage={(e, value) =>
                                                fetchFirmwares(null, value + 1)
                                            }
                                            onChangeRowsPerPage={(e, value) =>
                                                fetchFirmwares(e.target.value, null)
                                            }
                                        >
                                            {pager.totalEntries >= 5 && <span>More than 500</span>}
                                        </TablePagination>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Fab
                    className={style.createFirmwareFab}
                    style={{ position: "fixed" }}
                    onClick={() => toggleCreateNewDialog(true)}
                >
                    <Add />
                </Fab>
            </Container>
            <Dialog
                open={deleteDialog}
                id="deleteFlag"
                onClose={() => {
                    toggleDeleteDialog(false);
                    setFirmwareForDeletion(null);
                }}
            >
                <DialogTitle>{translate("firmwares_delete_flag_title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate("firmwares_confirm_delete")}{" "}
                        <strong>{firmwareForDeletion?.hwID}-{firmwareForDeletion?.version}</strong>?{" "}
                        {translate("flags_confirm_delete_note")}.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={confirmDelete} color="primary">
                        {translate("label_confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={createNewDialog}
                id="createNewFlag"
                onClose={() => {
                    toggleCreateNewDialog(false);
                    createFormik.resetForm();
                }}
                maxWidth="sm"
                fullWidth
                disableBackdropClick={creationInProgress}
            >
                <DialogTitle>{translate("firmwares_new_firmware")}</DialogTitle>
                <form onSubmit={createFormik.handleSubmit}>
                    <DialogContent>
                        <Box
                            style={{
                                paddingRight: 10,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="filled-number"
                                name="majorVersion"
                                variant="outlined"
                                style={{ marginRight: 10 }}
                                value={createFormik.values.majorVersion}
                                onChange={createFormik.handleChange}
                                onBlur={createFormik.handleBlur}
                                error={
                                    createFormik.touched.majorVersion &&
                                    Boolean(createFormik.errors.majorVersion)
                                }
                                helperText={
                                    createFormik.touched.majorVersion &&
                                    createFormik.errors.majorVersion
                                }
                                onInput={(e) => {
                                    e.target.value = Math.max(1, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 2);
                                }}
                                label="Major Version"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="filled-number"
                                name="minorVersion"
                                style={{ marginRight: 10 }}
                                label="Minor Version"
                                variant="outlined"
                                type="number"
                                value={createFormik.values.minorVersion}
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 2);
                                }}
                                onChange={createFormik.handleChange}
                                onBlur={createFormik.handleBlur}
                                error={
                                    createFormik.touched.minorVersion &&
                                    Boolean(createFormik.errors.minorVersion)
                                }
                                helperText={
                                    createFormik.touched.minorVersion &&
                                    createFormik.errors.minorVersion
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="filled-number"
                                name="revisionVersion"
                                label="Revision Version"
                                type="number"
                                variant="outlined"
                                value={createFormik.values.revisionVersion}
                                onChange={createFormik.handleChange}
                                onBlur={createFormik.handleBlur}
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 2);
                                }}
                                error={
                                    createFormik.touched.revisionVersion &&
                                    Boolean(createFormik.errors.revisionVersion)
                                }
                                helperText={
                                    createFormik.touched.revisionVersion &&
                                    createFormik.errors.revisionVersion
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box
                            style={{
                                marginTop: 30,
                                paddingRight: 10,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Box style={{ marginRight: 20 }}>
                                <InputLabel style={{ marginTop: 5, marginBottom: 5 }}>
                                    Hardware Version
                                </InputLabel>
                                <Select
                                    name="hwVersion"
                                    style={{ marginBottom: 5, width: 150 }}
                                    variant="outlined"
                                    id="select-hw-version"
                                    defaultValue=""
                                    error={
                                        createFormik.touched.hwVersion &&
                                        Boolean(createFormik.errors.hwVersion)
                                    }
                                    value={createFormik.values.hwVersion}
                                    onChange={createFormik.handleChange}
                                    onBlur={createFormik.handleBlur}
                                    type="text"
                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    label={translate("hardware_version")}
                                >
                                    {hwVersions !== null
                                        ? hwVersions.map((version) => {
                                            return (
                                                <MenuItem key={version.id} value={version.name}>
                                                    {version.name}
                                                </MenuItem>
                                            );
                                        })
                                        : null}
                                </Select>
                            </Box>
                            <Box>
                                <InputLabel style={{ marginTop: 5, marginBottom: 5 }}>
                                    Hardware Flavor
                                </InputLabel>
                                <Select
                                    name="hwFlavor"
                                    style={{ marginBottom: 5, width: 150 }}
                                    variant="outlined"
                                    id="select-hw-flavor"
                                    defaultValue=""
                                    error={
                                        createFormik.touched.hwFlavor &&
                                        Boolean(createFormik.errors.hwFlavor)
                                    }
                                    value={createFormik.values.hwFlavor}
                                    onChange={createFormik.handleChange}
                                    onBlur={createFormik.handleBlur}
                                    type="text"
                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    label={translate("hardware_flavor")}
                                >
                                    {hwFlavors !== null
                                        ? hwFlavors.map((flavor) => {
                                            return (
                                                <MenuItem key={flavor.id} value={flavor.name}>
                                                    {flavor.name}
                                                </MenuItem>
                                            );
                                        })
                                        : null}
                                </Select>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1, width: "25ch" },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <FileUploader
                                name="file"
                                type="file"
                                updateFilesCallback={updateUploadedFiles}
                                buttontitle="Upload File"
                            />
                            {uploadedFiles !== undefined &&
                                uploadedFiles.file &&
                                uploadedFiles.file.length > 0 ? (
                                <InputLabel style={{ marginTop: 15 }}>
                                    {uploadedFiles.file[0].name}
                                </InputLabel>
                            ) : null}
                        </Box>
                        <DialogActions>
                            <Button variant="contained" type="submit" color="primary">
                                {translate("label_upload")}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
            <Snackbar
                open={notification.active}
                autoHideDuration={5000}
                onClose={closeNotification}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert variant="filled" severity={notification.type}>
                    {notification.content}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}