import {
    getFirmwares,
} from '../../api/firmwares';

export function firmwares(params) {
    return async function firmwaresThunk(dispatch) {
        dispatch({ type: 'firmwares/loadFirmwares' });

        try {
            const response = await getFirmwares(params);
            dispatch({ type: 'firmwares/firmwaresLoaded', payload: response.data });
        } catch (e) {
            dispatch({ type: 'firmwares/loadFirmwaresFailed', payload: e.message });
        }
    }
}  