import {
    Grid,
    Container,
    TextField,
    Box,
    Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { 
    useFormik,
} from 'formik';
import * as Yup from 'yup';
import {
    useLocation,
    useHistory,
} from 'react-router-dom';
import { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import Button from '../../components/Button';
import style from './style.module.scss';
import useTranslate from '../../hooks/useTranslate';
import {
    resetPassword
} from '../../api/account';

function AcceptInvite() {
    const translate = useTranslate();
    const [notification, setNotification] = useState({
        active: false,
        type: '',
        content: '',
    });
    const query = useLocation().search;
    const queryParams = new URLSearchParams(query);
    const token = queryParams.get('token');
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            password: '',
            repeatPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required(translate('validation_required')).min(8, 'Requires 8 characters'),
            repeatPassword: Yup.string().required(translate('validation_required'))
                .test('passwordsMatch', translate('validation_password_mismatch'), function(value){
                    return this.parent.password === value;
                }),
        }),
        onSubmit: async (values) => {
            try {
                await resetPassword({...values, token});
                setNotification({
                    active: true,
                    type: 'success',
                    content: translate('reset_pass_success'),
                });

                setTimeout(() => {
                    // Navigate to home
                    history.push(`/`);
                }, 3000);
            } catch (e) {
                setNotification({
                    active: true,
                    type: 'error',
                    content: translate(e.message),
                });
            }
        }
    });
    const closeNotification = () => {
        setNotification({
            active: false,
        });
    };

    return (
        <Grid 
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={style.resetPassPage}>
            <Container maxWidth="xs"
                className={style.resetContainer}>
                <Box className={style.logo}>
                    <Logo />
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <TextField variant="outlined"
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        fullWidth
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="password"
                        size="small"
                        margin="dense"
                        label={translate('reset_pass_password')}/>
                    <TextField variant="outlined"
                        error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                        helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                        fullWidth
                        name="repeatPassword"
                        value={formik.values.repeatPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="password"
                        size="small"
                        margin="dense"
                        label={translate('reset_pass_repeat_password')}/>
                    <Button variant="contained"
                        type="submit"
                        disabled={formik.isSubmitting}
                        mt={12}
                        fullWidth
                        color="primary">
                        {translate('reset_pass_reset')}
                    </Button>
                </form>
            </Container>
            <Snackbar open={notification.active}
                autoHideDuration={5000}
                onClose={closeNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert variant="filled" severity={notification.type}>
                    {notification.content}
                </Alert>
            </Snackbar>
        </Grid>
        
    )
}

export default AcceptInvite;