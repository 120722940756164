import http from "./api";

export async function getFirmwares(params) {
    const qp = {
        ...params,
        order_dir: "desc",
    };
    try {
        const result = await http({
            method: "GET",
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: "/device/firmwares",
            params: qp,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function createFirmware(data) {
    try {
        const result = await http({
            method: "POST",
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/device/firmwares`,
            data,
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function deleteFirmware(id) {
    try {
        const result = await http({
            method: 'DELETE',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/device/firmwares/${id}`,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}