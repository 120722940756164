import http from './api';

export async function getTags() {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_ANALYTICS_BASE_URL,
            url: '/tags/?active=true&page=1&per_page=1000',
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}
