import {
    useEffect,
    useState,
} from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
    Container,
    Toolbar,
    Box,
    IconButton,
    TextField,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    Fab,
    CircularProgress,
    Snackbar,
} from '@material-ui/core';
import {
    Visibility,
    Replay,
    Search,
    Print,
} from '@material-ui/icons';
import {
    DateTimePicker
} from '@material-ui/pickers';
import {
    Alert,
} from '@material-ui/lab';
import ReactJson from "react-json-view";
import useTranslate from '../../hooks/useTranslate';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { functionalLogs } from '../../store/functional-logs/actionCreators';
import {
    getLogs,
    getPager,
} from '../../store/functional-logs/selectors';
import useThrottle from '../../hooks/useThrottle';
import style from './style.module.scss';
import {
    createFunctionalReport,
} from '../../api/logs';

function FunctionalLogs(props){
    const {
        toggleLoader,
    } = props;

    const dispatch = useDispatch();
    const translate = useTranslate();
    const rows = useSelector(getLogs);
    const pager = useSelector(getPager);
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [hwIdFilterValue, setHwIdFilterValue] = useState('');
    const [boxIdFilterValue, setBoxIdFilterValue] = useState('');
    const throttledGetLogs = useThrottle(() => fetchLogs(), 1000);
    const [detailsDialog, toggleDetailsDialog] = useState(false);
    const [logJson, setLogJson] = useState(null);
    const [creationInProgress, setCreationStatus] = useState(false);
    const [notification, setNotification] = useState({
        active: false,
        type: '',
        content: '',
    });
    
    const columns = [
        { id: 'id', label: translate('logs_table_id') },
        { id: 'mac', label: translate('logs_table__mac_addr') },
        { id: 'boxId', label: translate('logs_table__boxid') },
        { id: 'createdAt', label: translate('logs_table_created_at') },
        { id: 'details', label: translate('logs_table_details'), align: "center" },
    ];

    const fetchLogs = async (pageSize, pageNum) => {
        toggleLoader(true);

        const params = {
            timeFrom: dateFrom,
            timeTo: dateTo,
            per_page: pageSize || pager.size,
            page: pageNum || pager.page,
            hwId: hwIdFilterValue,
            boxId: boxIdFilterValue,
        };
        await dispatch(functionalLogs(params));
        toggleLoader(false);
    }

    const closeNotification = () => {
        setNotification({
            active: false,
        });
    };

    const generateReport = async () => {
        setCreationStatus(true);
        toggleLoader(true)

        try {
            await createFunctionalReport({
                timeFrom: dateFrom,
                timeTo: dateTo,
                hwId: hwIdFilterValue
            });
            setNotification({
                active: true,
                type: 'success',
                content: translate('reports_hw_logs_report_created'),
            });
        } catch (e) {
            setNotification({
                active: true,
                type: 'error',
                content: translate(e.message),
            });
        } finally {
            setCreationStatus(false);
            toggleLoader(false)
        }
    };

    useEffect(() => {
        fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, dateTo])

    useEffect(() => {
        throttledGetLogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hwIdFilterValue, boxIdFilterValue])

    return (
        <Container>
            <Toolbar>
                <Box  px={1}>
                    <DateTimePicker
                        style={{minWidth: 200}}
                        label={translate("label_datepicker_from")}
                        disableFuture={true}
                        value={dateFrom}
                        onChange={setDateFrom}
                    />
                </Box>
                <Box px={1}>
                    <DateTimePicker
                        style={{minWidth: 200}}
                        label={translate("label_datepicker_to")}
                        disableFuture={true}
                        value={dateTo}
                        onChange={setDateTo}
                    />
                </Box>
                <Box px={1}>
                    <TextField
                        type="search"
                        margin="none"
                        label={translate("label_search_hw_mac_addr")}
                        onChange={(e) => { setHwIdFilterValue(e.target.value) }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <Box px={1}>
                    <TextField
                        type="search"
                        margin="none"
                        label={translate("label_search_boxid")}
                        onChange={(e) => { setBoxIdFilterValue(e.target.value) }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <Box ml={"auto"}>
                    <IconButton onClick={() => fetchLogs()}>
                        <Replay />
                    </IconButton>
                </Box>
            </Toolbar>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => {
                                    return (
                                        <TableCell key={column.id}
                                            align={column.align || 'inherit'}>
                                            {column.label}
                                        </TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!rows?.length) && (
                            <TableRow>
                                <TableCell align="center" colSpan={6}>
                                    {translate("table_empty_data_set")}
                                </TableCell>
                            </TableRow>)
                        }
                        {rows && rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.values.hwId}</TableCell>
                                <TableCell>{row.values.boxId}</TableCell>
                                <TableCell>{row.createdAt}</TableCell>
                                <TableCell align="center">
                                    <Visibility 
                                        onClick={() => {
                                            setLogJson(row);
                                            toggleDetailsDialog(true);
                                        }}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell
                                colSpan="4"
                                style={{borderBottom: 'none'}}>
                                {pager && (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 50]}
                                        component="div"
                                        count={pager.totalEntries}
                                        rowsPerPage={pager.size}
                                        page={pager.page - 1}
                                        onChangePage={(e, value) => fetchLogs(null, value + 1)}
                                        onChangeRowsPerPage={(e, value) => fetchLogs(e.target.value, null)}
                                    />
                                )
                            }  
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Dialog open={detailsDialog}
                maxWidth="sm"
                fullWidth={true}
                id="detailsDialog"
                onClose={() => {
                    toggleDetailsDialog(false);
                    setLogJson({});
                }}>
                <DialogTitle>
                    {translate('logs_full_details')}
                </DialogTitle>
                <DialogContent>
                    <Box p={3}>
                        <ReactJson
                            src={logJson}
                            enableClipboard={false}/>
                    </Box>
                </DialogContent>
            </Dialog>
            <Fab variant="extended"
                disabled={creationInProgress || (!rows || rows.length < 1)}
                className={style.generateReportFab}
                onClick={generateReport}
                style={{position: "fixed"}}>
                <Print />
                {translate("reports_generate_report")}
                {creationInProgress && <CircularProgress className={style.fabLoader} size={24} />}
            </Fab>
            <Snackbar open={notification.active}
                autoHideDuration={5000}
                onClose={closeNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert variant="filled" severity={notification.type}>
                    {notification.content}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default FunctionalLogs;