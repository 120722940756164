import reqEnum from '../../utils/request-statuses';

const initialState = {
    status: null,
    logs: null,
    pager: {
        size: 20,
        page: 1,
        totalEntries: 0,
    },
    error: '',
};

export default function functionalLogsReducer(state = initialState, action) {
    switch (action.type) {
        case 'functionalLogs/loadLogs':
            return {
                ...state,
                status: reqEnum.loading
            };
        case 'functionalLogs/logsLoaded':
            const {
                paginator,
            } = action.payload;
            const pager = {
                totalEntries: paginator.total_entries_size,
                currentEntries: paginator.current_entries_size,
                page: paginator.page,
                size: paginator.per_page,
            };
            
            return {
                ...state,
                status: reqEnum.fullfilled,
                error: '',
                logs: action.payload.results,
                pager: pager,
            };
        case 'functionalLogs/loadLogsFailed':
            return {
                ...state,
                status: reqEnum.rejected,
                error: action.payload,
            };
        default:
            return state;
    }
}