import { useSelector } from 'react-redux';
import {
    Redirect,
    Route,
} from 'react-router-dom';
import { isLoggedIn } from '../../store/auth/selectors';

function PublicRoute({ children, ...rest }) {
    const loggedIn = useSelector(isLoggedIn)

    return (
        <Route
            {...rest}
            render={() => 
                loggedIn === false ? (
                    children
                ) : (
                    <Redirect to="/" />
                )
            } 
        />
    )
}

export default PublicRoute;