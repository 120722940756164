import reqEnum from '../../utils/request-statuses';

const initialState = {
    status: null,
    firmwares: null,
    pager: {
        size: 20,
        page: 1,
        totalEntries: 0,
    },
    error: '',
};

export default function firmwaresReducer(state = initialState, action) {
    switch (action.type) {
        case 'firmwares/loadFirmwares':
            return {
                ...state,
                status: reqEnum.loading
            };
        case 'firmwares/firmwaresLoaded':
            return {
                ...state,
                status: reqEnum.fullfilled,
                error: '',
                firmwares: action.payload.results,
                pager: null,
            };
        case 'firmwares/loadFirmwaresFailed':
            return {
                ...state,
                status: reqEnum.rejected,
                error: action.payload,
            };
        default:
            return state;
    }
}  