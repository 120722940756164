import http from './api';

export async function getGoals(params) {
    const qp = {
        ...params,
        'order_dir': 'desc',
    }
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/goals/',
            params: qp,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function getGoal(id) {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/goals/${id}`,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function editGoal(id, data) {
    try {
        const result = await http({
            method: 'PUT',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/goals/${id}`,
            data
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function createGoal(data) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/goals`,
            data
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function deleteGoal(id) {
    try {
        const result = await http({
            method: 'DELETE',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/goals/${id}`,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}
