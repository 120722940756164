import {
    CircularProgress,
    Grid,
} from '@material-ui/core';

function OverlayLoader() {
    return (
        <Grid container
            direction="column"
            alignItems="center"
            justify="center"
            style={{minHeight: '100vh'}}>
            <CircularProgress />
        </Grid>

    );
}

export default OverlayLoader;