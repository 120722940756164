import {
    getUsers,
} from '../../api/users';

export function users(params) {
    return async function usersThunk(dispatch) {
        dispatch({type: 'users/loadUsers'});

        try {
            const response = await getUsers(params);
            dispatch({type: 'users/usersLoaded', payload: response.data});
        } catch (e) {
            dispatch({type: 'users/loadUsersFailed', payload: e.message});
        }
    }
}