import reqEnum from '../../utils/request-statuses';

const initialState = {
    status: null,
    flags: null,
    error: '',
};

export default function featureFlagsReducer(state = initialState, action) {
    switch (action.type) {
        case 'featureFlags/loadFlags':
            return {
                ...state,
                status: reqEnum.loading
            };
        case 'featureFlags/flagsLoaded':
            return {
                ...state,
                status: reqEnum.fullfilled,
                error: '',
                flags: action.payload,
            };
        case 'featureFlags/loadFlagsFailed':
            return {
                ...state,
                status: reqEnum.rejected,
                error: action.payload,
            };
        default:
            return state;
    }
}