import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { useEffect } from 'react';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import AcceptInvite from './pages/AcceptInvite/AcceptInvite';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import OverlayLoader from './components/OverlayLoader';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import PublicRoute from './components/PublicRoute/PublicRoute';
import { loadProfile } from './store/auth/actionCreators';
import {
    authStatus,
    authLoadingFailed,
    getProfile,
    authLoadingInProgress,
} from './store/auth/selectors';

function App() {
    const dispatch = useDispatch();
    const authenticated = useSelector(authStatus)
    const authFailed = useSelector(authLoadingFailed);
    const authInProgress = useSelector(authLoadingInProgress);
    const user = useSelector(getProfile);

    useEffect(() => {
        if (user !== null || authInProgress || authFailed) return;
        dispatch(loadProfile());
    })

    if (authenticated === null || authInProgress) {
        return (<OverlayLoader />);
    }

    return (
        <div>
            <Router>
                <Switch>
                    <Route exact path="/accept-invite">
                        <AcceptInvite />
                    </Route>
                    <Route exact path="/reset-password">
                        <ResetPassword />
                    </Route>
                    <PublicRoute path="/login">
                        <Login />
                    </PublicRoute>                    
                    <ProtectedRoute path="/">
                        <Home />
                    </ProtectedRoute>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
