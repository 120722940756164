import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/reducer";
import translateReducer from "./translations/reducer";
import sessionReportReducer from "./session-reports/reducer";
import tagsReducer from "./tags/reducer";
import usersReducer from "./users/reducer";
import featureFlagsReducer from "./feature-flags/reducer";
import pcbaLogsReducer from "./pcba-logs/reducer";
import functionalLogsReducer from "./functional-logs/reducer";
import nudges from "./nudges/reducer";
import firmwares from "./firmwares/reducer";
import goals from "./goals/reducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    translations: translateReducer,
    sessionReports: sessionReportReducer,
    tags: tagsReducer,
    users: usersReducer,
    featureFlags: featureFlagsReducer,
    pcbaLogs: pcbaLogsReducer,
    functionalLogs: functionalLogsReducer,
    nudges: nudges,
    firmwares: firmwares,
    goals: goals,
  },
});

export default store;