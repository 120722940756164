import React, { useState } from "react";
import PropTypes from "prop-types";

const ExpandSection = ({ data }) => {
  const [state, setState] = useState({ collapseOpen: false });

  const toggle = () => {
    setState({ collapseOpen: !state.collapseOpen });
  };

  const style = {
    margin: "0",
  };

  if (!data || data.length === 0) {
    return null;
  }

  return state.collapseOpen ? (
    <div>
      <p style={style}>{data}</p>
      <button
        style={{
          backgroundColor: "transparent",
          color: "#25262d",
          lineHeight: 1.5,
          padding: "0.5rem",
          fontWeight: 600,
          borderColor: "transparent",
        }}
        type="button"
        onClick={toggle}
      >
        Show Less
      </button>
    </div>
  ) : (
    <div>
      {data.length > 20 ? (
        <div>
          <p style={style}>{data.slice(0, 20) + "..."}</p>
          <button
            style={{
              backgroundColor: "transparent",
              color: "#25262d",
              lineHeight: 1.5,
              padding: "0.5rem",
              fontWeight: 600,
              borderColor: "transparent",
            }}
            type="button"
            onClick={toggle}
          >
            Show More
          </button>
        </div>
      ) : (
        <p style={style}>{data}</p>
      )}
    </div>
  );
};

ExpandSection.propTypes = {
  partner: PropTypes.any,
};

export default ExpandSection;
