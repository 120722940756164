import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import UploadFileIcon from "../assets/upload_file.svg";

// Style the Button component
const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
  background: #ffffff;
  border-radius: 3px;
  margin-top: 8px;
  border-color: transparent;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 120%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  color: #000000;
`;

const FileUploader = ({
  updateFilesCallback: updateFileCallback,
  ...otherProps
}) => {
  const fileInputField = React.useRef(null);
  const handleClick = () => {
    fileInputField.current.click();
  };

  const [loadedFile, setLoadedFile] = useState({
    file: null,
    valid: true,
    fileType: "",
  });
  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setLoadedFile({
        file: URL.createObjectURL(e.target.files[0]),
        valid: true,
        fileType: e.target.files[0].type,
      });
      updateFileCallback({
        name: otherProps.name,
        value: e.target.files,
      });
    }
  };

  const previewFile = () => {
    if (loadedFile.file) {
      return loadedFile.valid ? (
        <img
          src={loadedFile.file}
          alt=""
          height="90px"
          width="90px"
          style={{ marginTop: 5, marginLeft: 17 }}
        />
      ) : (
        <div>
          <img
            src={loadedFile.file}
            alt=""
            height="90px"
            width="90px"
            style={{ marginTop: 5, marginLeft: 17 }}
          />
          <small className="col-12 mt-2 p-0" style={{ color: "red" }}>
            {"This is not recommended upload image size. Image size is > 20MB."}
          </small>
        </div>
      );
    }

    if (otherProps.preview && otherProps.preview !== "") {
      return (
        <img
          src={otherProps.preview}
          alt=""
          height="90px"
          width="90px"
          style={{ marginTop: 5, marginLeft: 17 }}
        />
      );
    }
  };

  return (
    <>
      <br />
      {loadedFile.fileType !== "" && loadedFile.fileType !== "application/zip"
        ? previewFile()
        : null}
      <br />
      <Button
        data-testid="font-button"
        type="button"
        onClick={handleClick}
        style={{ border: "1px solid rgba(0, 0, 0, 0.30)" }}
      >
        <img
          style={{ width: 14, height: 14, marginRight: 15 }}
          src={UploadFileIcon}
          alt="upload_file"
        />
        {otherProps.buttontitle ? otherProps.buttontitle : "Upload Content"}
      </Button>
      <input
        type="file"
        ref={fileInputField}
        accept=".jpg, .png, .jpeg, .gif, .zip"
        hidden
        onChange={handleChange}
        {...otherProps}
      />
    </>
  );
};

FileUploader.propTypes = {
  home: PropTypes.bool,
};

export default FileUploader;