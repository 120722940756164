import reqEnum from '../../utils/request-statuses';

const initialState = {
    status: null,
    tags: [],
    error: '',
};

export default function tagsReducer(state = initialState, action) {
    switch (action.type) {
        case 'sessionReports/loadTags':
            return {
                ...state,
                status: reqEnum.loading
            };
        case 'sessionReports/tagsLoaded':
            return {
                ...state,
                status: reqEnum.fullfilled,
                error: '',
                tags: action.payload,
            };
        case 'sessionReports/loadTagsFailed':
            return {
                ...state,
                status: reqEnum.rejected,
                error: action.payload,
            };
        default:
            return state;
    }
}