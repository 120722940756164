import {
    getPCBALogs,
} from '../../api/logs';

export function pcbaLogs(params) {
    return async function eventsThunk(dispatch) {
        dispatch({type: 'pcbaLogs/loadLogs'});

        try {
            const response = await getPCBALogs(params);
            dispatch({type: 'pcbaLogs/logsLoaded', payload: response.data});
        } catch (e) {
            dispatch({type: 'pcbaLogs/loadLogsFailed', payload: e.message});
        }
    }
}