import reqEnum from '../../utils/request-statuses';

const initialState = {
    status: null,
    events: null,
    pager: {
        size: 20,
        page: 1,
        totalEntries: 0,
    },
    error: '',
};

export default function sessionReportReducer(state = initialState, action) {
    switch (action.type) {
        case 'sessionReports/loadEvents':
            return {
                ...state,
                status: reqEnum.loading
            };
        case 'sessionReports/eventsLoaded':
            const {
                paginator,
            } = action.payload;
            const pager = {
                totalEntries: paginator.total_entries_size,
                currentEntries: paginator.current_entries_size,
                page: paginator.page,
                size: paginator.per_page,
            };
            return {
                ...state,
                status: reqEnum.fullfilled,
                error: '',
                events: action.payload.results,
                pager: pager,
            };
        case 'sessionReports/loadEventsFailed':
            return {
                ...state,
                status: reqEnum.rejected,
                error: action.payload,
            };
        default:
            return state;
    }
}