import http from './api';

export async function getPCBALogs(params) {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_ANALYTICS_BASE_URL,
            url: '/device/logs/pcba',
            params,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function getFunctionalLogs(params) {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_ANALYTICS_BASE_URL,
            url: '/device/logs/functional',
            params,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function createPCBAReport(data) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_ANALYTICS_BASE_URL,
            url: '/reporting/logs/pcba',
            data,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function createFunctionalReport(data) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_ANALYTICS_BASE_URL,
            url: '/reporting/logs/functional',
            data,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}