import http from './api';

export async function getUsers(params) {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: '/v1/users/',
            params,
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}


export async function getUsersByID(id) {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_BASE_URL,
            url: `/v1/users/${id}`,
        });
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}
