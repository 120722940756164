import {
    Grid,
    Container,
    TextField,
    Box,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState, useEffect } from 'react';
import { 
    useFormik,
} from 'formik';
import * as Yup from 'yup';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import Button from '../../components/Button';
import style from './style.module.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import useTranslate from '../../hooks/useTranslate';
import { auth } from '../../store/auth/actionCreators';
import { authError } from '../../store/auth/selectors';
import {
    forgotPassword,
} from '../../api/account';

function Login() {
    const [notification, setNotification] = useState({
        active: false,
        type: '',
        content: '',
    });
    const dispatch = useDispatch();
    const translate = useTranslate();
    const authErr = useSelector(authError);
    const loginFormik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(translate('validation_required')),
            password: Yup.string().required(translate('validation_required')),
        }),
        onSubmit: async (values) => {
            await dispatch(auth(values));
        }
    });
    const closeNotification = () => {
        setNotification({
            active: false,
        });
    };
    const [forgotPassDialog, toggleForgotPassDialog] = useState(false);
    const resetFormik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email(translate('validation_invalid_mail')).required(translate('validation_required')),
        }),
        onSubmit: async (values) => {
            try {
                await forgotPassword(values)
                setNotification({
                    active: true,
                    type: 'success',
                    content: translate('login_new_passwrod_requested'),
                });
                toggleForgotPassDialog(false)
            } catch (e) {
                setNotification({
                    active: true,
                    type: 'error',
                    content: translate(e.message),
                });
            }
        }
    });

    useEffect(() => {
        if (!authErr) return;
        setNotification({
            active: true,
            type: 'error',
            content: translate(authErr),
        });
    }, [authErr, translate]);
  
    return (
        <Grid 
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={style.loginPage}>
            <Container maxWidth="xs"
                className={style.loginContainer}>
                <Box className={style.logo}>
                    <Logo />
                </Box>
                <form onSubmit={loginFormik.handleSubmit}>
                    <TextField variant="outlined"
                        error={loginFormik.touched.email && !!loginFormik.errors.email}
                        helperText={loginFormik.errors.email}
                        fullWidth
                        name="email"
                        value={loginFormik.email}
                        onChange={loginFormik.handleChange}
                        onBlur={loginFormik.handleBlur}
                        type="email"
                        size="small"
                        margin="dense"
                        label={translate('login_email')}/>
                    <TextField variant="outlined"
                        error={loginFormik.touched.password && !!loginFormik.errors.password}
                        helperText={loginFormik.errors.password}
                        fullWidth
                        name="password"
                        value={loginFormik.password}
                        onChange={loginFormik.handleChange}
                        onBlur={loginFormik.handleBlur}
                        type="password"
                        size="small"
                        margin="dense"
                        label={translate('login_password')}/>
                    <Button variant="contained"
                        type="submit"
                        disabled={loginFormik.isSubmitting}
                        mt={8}
                        fullWidth
                        color="primary">
                        {translate('login_login')}
                    </Button>
                    <Box className={style.forgotPass}>
                        <Button variant="text"
                            disabled={loginFormik.isSubmitting}
                            onClick={() => {
                                toggleForgotPassDialog(true)
                            }}
                            mt={2}
                            color="secondary">
                            {translate('login_forgot_password')}
                        </Button>
                    </Box>
                </form>
            </Container>
            <Dialog open={forgotPassDialog}
                maxWidth="sm"
                fullWidth={true}
                id="forgotPassDialog"
                onClose={() => {
                    toggleForgotPassDialog(false);
                }}>
                <DialogTitle>
                    {translate('login_forgot_pass_title')}
                </DialogTitle>
                <DialogContent>
                    <Box pb={4}>
                        <form onSubmit={resetFormik.handleSubmit}>
                            <TextField variant="outlined"
                                error={resetFormik.touched.email && !!resetFormik.errors.email}
                                helperText={resetFormik.errors.email}
                                fullWidth
                                name="email"
                                value={resetFormik.email}
                                onChange={resetFormik.handleChange}
                                onBlur={resetFormik.handleBlur}
                                type="email"
                                size="small"
                                margin="dense"
                                label={translate('login_email')}/>
                            <Button variant="contained"
                                type="submit"
                                mt={4}
                                fullWidth
                                color="primary">
                                {translate('login_forgot_password_request')}
                            </Button>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar open={notification.active}
                autoHideDuration={5000}
                onClose={closeNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert variant="filled" severity={notification.type}>
                    {notification.content}
                </Alert>
            </Snackbar>
        </Grid>
    )
}

export default Login;