import http from './api';

export async function getEvents(params = {}) {
    const qp = {
        ...params,
        excludeFrames: true,
    };
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_ANALYTICS_BASE_URL,
            url: '/sessions/events',
            params: qp,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function getEventById(eventId) {
    try {
        const result = await http({
            method: 'GET',
            baseURL: process.env.REACT_APP_MENDI_ANALYTICS_BASE_URL,
            url: `/sessions/events/${eventId}`,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}

export async function createReport(data) {
    try {
        const result = await http({
            method: 'POST',
            baseURL: process.env.REACT_APP_MENDI_ANALYTICS_BASE_URL,
            url: '/reporting/session-events',
            data,
        })
        return result.data;
    } catch (e) {
        throw new Error(e.response.data.error.translationKey);
    }
}