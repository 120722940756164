import React, { useState, useEffect } from "react";
import {
  LinearProgress,
  Container,
  Box,
  Breadcrumbs,
  Typography,
  Link,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Button,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { Add, Edit, Delete } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import useTranslate from "../../hooks/useTranslate";
import style from "./style.module.scss";
import { createNudge, deleteNudge, editNudge } from "../../api/nudges";
import { getGoals } from "../../store/goals/selectors";
import { getNudges, getPager } from "../../store/nudges/selectors";
import { nudges } from "../../store/nudges/actionCreators";
import { goals } from "../../store/goals/actionCreators";
import UploadAPI from "../../api/upload";
import FileUploader from "../../components/FileUploader";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import ExpandSection from "../../components/ExpandSection/expand-section";

export default function Nudges() {
  const loadNudgesInProgress = false;
  const history = useHistory();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const goalRows = useSelector(getGoals);
  const rows = useSelector(getNudges);
  const pager = useSelector(getPager);
  const columns = [
    { id: "title", label: translate("nudges_table_title") },
    { id: "smallTitle", label: translate("nudges_table_small_title") },
    { id: "paragraphOne", label: translate("nudges_table_paragraph_one") },
    { id: "paragraphTwo", label: translate("nudges_table_paragraph_two") },
    { id: "image", label: translate("nudges_table_image") },
    { id: "previewImage", label: translate("nudges_table_preview_image") },
    { id: "active", label: translate("nudges_table_active") },
    { id: "goalID", label: translate("nudges_table_goal_id") },
    { id: "createdAt", label: translate("flags_table_created_at") },
    { id: "edit", label: translate("flags_table_edit"), align: "center" },
    { id: "delete", label: translate("flags_table_delete"), align: "center" },
  ];
  const [createNewDialog, toggleCreateNewDialog] = useState(false);
  const [deleteDialog, toggleDeleteDialog] = useState(false);
  const [nudgeForDeletion, setNudgeForDeletion] = useState(null);
  const [creationInProgress] = useState(false);
  const [editDialog, toggleEditDialog] = useState(false);
  const [nudgeForEdit, setNudgeForEdit] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState();

  useEffect(() => {
    dispatch(nudges());
    dispatch(goals());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUploadedFiles = (file) => {
    setUploadedFiles({ ...uploadedFiles, [file.name]: file.value });
  };
  const createFormik = useFormik({
    initialValues: {
      title: "",
      smallTitle: "",
      paragraphOne: "",
      paragraphTwo: "",
      image: "",
      previewImage: "",
      goalID: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(translate("validation_required")),
      smallTitle: Yup.string().required(translate("validation_required")),
      paragraphOne: Yup.string().required(translate("validation_required")),
      paragraphTwo: Yup.string().required(translate("validation_required")),
      goalID: Yup.string().required(translate("validation_required")),
    }),
    onSubmit: async (values) => {
      try {
        if (uploadedFiles.file && uploadedFiles.file.length > 0) {
          const file = uploadedFiles.file[0];
          const params = {
            filename: file.name,
            "content-type": file.type,
            foldername: "nudges",
          };

          const imageData = await UploadAPI.GenerateUploadURL(params);
          await UploadAPI.UploadFile(file, imageData.data.url);
          values.image = imageData.data.accessURL;
          setUploadedFiles();
        }
        if (
          uploadedFiles.preview_img_file &&
          uploadedFiles.preview_img_file.length > 0
        ) {
          const file = uploadedFiles.preview_img_file[0];
          const params = {
            filename: file.name,
            "content-type": file.type,
            foldername: "nudges",
          };

          const imageData = await UploadAPI.GenerateUploadURL(params);
          await UploadAPI.UploadFile(file, imageData.data.url);
          values.previewImage = imageData.data.accessURL;
          setUploadedFiles();
        }

        await createNudge(values);
        dispatch(nudges());
        toggleCreateNewDialog(false);
        setNotification({
          active: true,
          type: "success",
          content: translate("nudges_nudge_created"),
        });
      } catch (e) {
        setNotification({
          active: true,
          type: "error",
          content: translate(e.message),
        });
      }
    },
  });
  const [notification, setNotification] = useState({
    active: false,
    type: "",
    content: "",
  });
  const closeNotification = () => {
    setNotification({
      active: false,
    });
  };
  const fetchNudges = (pageSize, pageNum) => {
    const params = {
      per_page: pageSize || pager.size,
      page: pageNum || pager.page,
    };
    dispatch(nudges(params));
  };
  const confirmDelete = async () => {
    try {
      await deleteNudge(nudgeForDeletion.id);
      dispatch(nudges());
      setNudgeForDeletion(null);
      toggleDeleteDialog(false);
      setNotification({
        active: true,
        type: "success",
        content: translate("nudges_nudge_deleted"),
      });
    } catch (e) {
      setNotification({
        active: true,
        type: "error",
        content: translate(e.message),
      });
    }
  };

  const setActive = async (data) => {
    try {
      await editNudge(data.id, data);
      setNotification({
        active: true,
        type: "success",
        content: translate("nudges_nudge_updated"),
      });
    } catch (e) {
      setNotification({
        active: true,
        type: "error",
        content: translate(e.message),
      });
    }
  };

  const editFormik = useFormik({
    initialValues: {
      title: nudgeForEdit?.title,
      smallTitle: nudgeForEdit?.smallTitle,
      paragraphOne: nudgeForEdit?.paragraphOne,
      paragraphTwo: nudgeForEdit?.paragraphTwo,
      goalID: nudgeForEdit?.goalID,
      image: nudgeForEdit?.image,
      previewImage: nudgeForEdit?.previewImage,
      active: nudgeForEdit?.active,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(translate("validation_required")),
      smallTitle: Yup.string().required(translate("validation_required")),
      paragraphOne: Yup.string().required(translate("validation_required")),
      paragraphTwo: Yup.string().required(translate("validation_required")),
      goalID: Yup.string().required(translate("validation_required")),
    }),
    onSubmit: async (values) => {
      try {
        if (
          uploadedFiles !== undefined &&
          uploadedFiles.file &&
          uploadedFiles.file.length > 0
        ) {
          const file = uploadedFiles.file[0];
          const params = {
            filename: file.name,
            "content-type": file.type,
            foldername: "nudges",
          };

          const imageData = await UploadAPI.GenerateUploadURL(params);
          await UploadAPI.UploadFile(file, imageData.data.url);
          values.image = imageData.data.accessURL;
          setUploadedFiles();
        }

        if (
          uploadedFiles !== undefined &&
          uploadedFiles.preview_img_file &&
          uploadedFiles.preview_img_file.length > 0
        ) {
          const file = uploadedFiles.preview_img_file[0];
          const params = {
            filename: file.name,
            "content-type": file.type,
            foldername: "nudges",
          };

          const imageData = await UploadAPI.GenerateUploadURL(params);
          await UploadAPI.UploadFile(file, imageData.data.url);
          values.previewImage = imageData.data.accessURL;
          setUploadedFiles();
        }

        await editNudge(nudgeForEdit.id, values);
        dispatch(nudges());
        toggleEditDialog(false);
        setNotification({
          active: true,
          type: "success",
          content: translate("nudges_nudge_updated"),
        });
      } catch (e) {
        setNotification({
          active: true,
          type: "error",
          content: translate(e.message),
        });
      }
    },
  });
  const prepareNudgeForEdit = (nudge) => {
    setNudgeForEdit(nudge);
    editFormik.initialValues.title = nudge.title;
    editFormik.initialValues.smallTitle = nudge.smallTitle;
    editFormik.initialValues.paragraphOne = nudge.paragraphOne;
    editFormik.initialValues.paragraphTwo = nudge.paragraphTwo;
    editFormik.initialValues.goalID = nudge.goalID;
    editFormik.initialValues.image = nudge.image;
    editFormik.initialValues.previewImage = nudge.previewImage;
    editFormik.initialValues.active = nudge.active;
  };

  const renderImage = (url) => {
    const src = url !== null ? url : "";
    return (
      <div className="avatar">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            src={src}
            style={{ height: 40, width: 40, borderRadius: "0.2rem" }}
            alt="nudge_image"
          />
        </a>
      </div>
    );
  };

  const renderYesNo = (val) => {
    return val === true ? "Yes" : "No";
  };

  const renderShowMore = (data) => {
    return <ExpandSection data={data} />;
  };

  return (
    <React.Fragment>
      {loadNudgesInProgress && <LinearProgress />}
      <Container>
        <Box py={2}>
          <Breadcrumbs>
            <Link color="inherit" to="/" onClick={() => history.push(`/`)}>
              <Typography color="textPrimary">
                {translate("breadcrumbs_home")}
              </Typography>
            </Link>
            <Typography color="textPrimary">
              {translate("breadcrumbs_nudges")}
            </Typography>
          </Breadcrumbs>
        </Box>
      </Container>
      <Container className={style.nudgesContainer}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align || "inherit"}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!rows?.length && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    {translate("table_empty_data_set")}
                  </TableCell>
                </TableRow>
              )}
              {rows &&
                rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.smallTitle}</TableCell>
                    <TableCell>{renderShowMore(row.paragraphOne)}</TableCell>
                    <TableCell>{renderShowMore(row.paragraphTwo)}</TableCell>
                    <TableCell>{renderImage(row.image)}</TableCell>
                    <TableCell>{renderImage(row.previewImage)}</TableCell>
                    <TableCell>{renderYesNo(row.active)}</TableCell>
                    <TableCell>{row.goalID}</TableCell>
                    <TableCell>{row.createdAt}</TableCell>
                    <TableCell align="center">
                      <Edit
                        onClick={() => {
                          prepareNudgeForEdit(row);
                          toggleEditDialog(true);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Delete
                        onClick={() => {
                          setNudgeForDeletion(row);
                          toggleDeleteDialog(true);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ThreeDotsMenu data={row} onMenuItemClicked={setActive} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan="5" style={{ borderBottom: "none" }}>
                  {pager && (
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50]}
                      component="div"
                      count={pager.totalEntries}
                      rowsPerPage={pager.size}
                      page={pager.page - 1}
                      onChangePage={(e, value) => fetchNudges(null, value + 1)}
                      onChangeRowsPerPage={(e, value) =>
                        fetchNudges(e.target.value, null)
                      }
                    >
                      {pager.totalEntries >= 5 && <span>More than 500</span>}
                    </TablePagination>
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Fab
          className={style.createNudgeFab}
          style={{ position: "fixed" }}
          onClick={() => toggleCreateNewDialog(true)}
        >
          <Add />
        </Fab>
      </Container>
      <Dialog
        open={deleteDialog}
        id="deleteFlag"
        onClose={() => {
          toggleDeleteDialog(false);
          setNudgeForDeletion(null);
        }}
      >
        <DialogTitle>{translate("nudges_delete_flag_title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("nudges_confirm_delete")}{" "}
            <strong>{nudgeForDeletion?.title}</strong>?{" "}
            {translate("flags_confirm_delete_note")}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmDelete} color="primary">
            {translate("label_confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialog}
        id="editFlag"
        onClose={() => {
          toggleEditDialog(false);
          setNudgeForEdit(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{translate("nudges_edit_nudge_title")}</DialogTitle>
        <form onSubmit={editFormik.handleSubmit}>
          <DialogContent>
            <TextField
              variant="outlined"
              error={
                editFormik.touched.title && Boolean(editFormik.errors.title)
              }
              helperText={editFormik.touched.title && editFormik.errors.title}
              fullWidth
              name="title"
              value={editFormik.values.title}
              onChange={editFormik.handleChange}
              onBlur={editFormik.handleBlur}
              type="text"
              size="small"
              margin="dense"
              label={translate("nudges_title")}
            />
            <TextField
              variant="outlined"
              error={
                editFormik.touched.smallTitle &&
                Boolean(editFormik.errors.smallTitle)
              }
              helperText={
                editFormik.touched.smallTitle && editFormik.errors.smallTitle
              }
              fullWidth
              name="smallTitle"
              value={editFormik.values.smallTitle}
              onChange={editFormik.handleChange}
              onBlur={editFormik.handleBlur}
              type="text"
              size="small"
              margin="dense"
              label={translate("nudges_small_title")}
            />
            <TextField
              variant="outlined"
              error={
                editFormik.touched.paragraphOne &&
                Boolean(editFormik.errors.paragraphOne)
              }
              helperText={
                editFormik.touched.paragraphOne &&
                editFormik.errors.paragraphOne
              }
              name="paragraphOne"
              value={editFormik.values.paragraphOne}
              onChange={editFormik.handleChange}
              onBlur={editFormik.handleBlur}
              fullWidth
              rows={4}
              rowsMax={8}
              type="text"
              margin="dense"
              multiline
              label={translate("nudges_paragraph_one")}
            />
            <TextField
              variant="outlined"
              error={
                editFormik.touched.paragraphTwo &&
                Boolean(editFormik.errors.paragraphTwo)
              }
              helperText={
                editFormik.touched.paragraphTwo &&
                editFormik.errors.paragraphTwo
              }
              name="paragraphTwo"
              value={editFormik.values.paragraphTwo}
              onChange={editFormik.handleChange}
              onBlur={editFormik.handleBlur}
              fullWidth
              rows={4}
              rowsMax={8}
              type="text"
              margin="dense"
              multiline
              label={translate("nudges_paragraph_two")}
            />

            <InputLabel style={{ marginTop: 5, marginBottom: 5 }}>
              Goal
            </InputLabel>
            <Select
              name="goalID"
              style={{ marginBottom: 5 }}
              variant="outlined"
              id="select-goal-id"
              fullWidth
              error={
                editFormik.touched.goalID && Boolean(editFormik.errors.goalID)
              }
              helperText={editFormik.touched.goalID && editFormik.errors.goalID}
              value={editFormik.values.goalID}
              onChange={editFormik.handleChange}
              onBlur={editFormik.handleBlur}
              type="text"
              size="small"
              margin="dense"
              label={translate("nudges_goal")}
            >
              {goalRows !== null
                ? goalRows.map((goal) => {
                    return (
                      <MenuItem key={goal.id} value={goal.id}>
                        {goal.name}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
            <InputLabel
              style={{
                marginTop: 10,
                marginBottom: 5,
              }}
            >
              GIF
            </InputLabel>
            <FileUploader
              name="file"
              type="file"
              preview={editFormik.values.image}
              updateFilesCallback={updateUploadedFiles}
              buttontitle="Upload File"
            />
            <InputLabel
              style={{
                marginTop: 10,
                marginBottom: 5,
                paddingTop: 10,
                borderTop: "1px solid rgba(0, 0, 0, 0.24)",
              }}
            >
              Preview Image
            </InputLabel>
            <FileUploader
              name="preview_img_file"
              type="file"
              preview={editFormik.values.previewImage}
              updateFilesCallback={updateUploadedFiles}
              buttontitle="Upload File"
            />
            <DialogActions>
              <Button variant="contained" type="submit" color="primary">
                {translate("label_save")}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
      <Dialog
        open={createNewDialog}
        id="createNewFlag"
        onClose={() => {
          toggleCreateNewDialog(false);
          createFormik.resetForm();
        }}
        maxWidth="sm"
        fullWidth
        disableBackdropClick={creationInProgress}
      >
        <DialogTitle>{translate("nudges_new_nudge")}</DialogTitle>
        <form onSubmit={createFormik.handleSubmit}>
          <DialogContent>
            <TextField
              variant="outlined"
              error={
                createFormik.touched.title && Boolean(createFormik.errors.title)
              }
              helperText={
                createFormik.touched.title && createFormik.errors.title
              }
              fullWidth
              name="title"
              value={createFormik.values.title}
              onChange={createFormik.handleChange}
              onBlur={createFormik.handleBlur}
              type="text"
              size="small"
              margin="dense"
              label={translate("nudges_title")}
            />
            <TextField
              variant="outlined"
              error={
                createFormik.touched.smallTitle &&
                Boolean(createFormik.errors.smallTitle)
              }
              helperText={
                createFormik.touched.smallTitle &&
                createFormik.errors.smallTitle
              }
              fullWidth
              name="smallTitle"
              value={createFormik.values.smallTitle}
              onChange={createFormik.handleChange}
              onBlur={createFormik.handleBlur}
              type="text"
              size="small"
              margin="dense"
              label={translate("nudges_small_title")}
            />
            <TextField
              variant="outlined"
              error={
                createFormik.touched.paragraphOne &&
                Boolean(createFormik.errors.paragraphOne)
              }
              helperText={
                createFormik.touched.paragraphOne &&
                createFormik.errors.paragraphOne
              }
              name="paragraphOne"
              value={createFormik.values.paragraphOne}
              onChange={createFormik.handleChange}
              onBlur={createFormik.handleBlur}
              fullWidth
              rows={4}
              rowsMax={8}
              type="text"
              margin="dense"
              multiline
              label={translate("nudges_paragraph_one")}
            />
            <TextField
              variant="outlined"
              error={
                createFormik.touched.paragraphTwo &&
                Boolean(createFormik.errors.paragraphTwo)
              }
              helperText={
                createFormik.touched.paragraphTwo &&
                createFormik.errors.paragraphTwo
              }
              name="paragraphTwo"
              value={createFormik.values.paragraphTwo}
              onChange={createFormik.handleChange}
              onBlur={createFormik.handleBlur}
              fullWidth
              rows={4}
              rowsMax={8}
              type="text"
              margin="dense"
              multiline
              label={translate("nudges_paragraph_two")}
            />
            <InputLabel style={{ marginTop: 5, marginBottom: 5 }}>
              Goal
            </InputLabel>
            <Select
              name="goalID"
              style={{ width: "100%", marginBottom: 5 }}
              variant="outlined"
              id="select-goal-id"
              error={
                createFormik.touched.goalID &&
                Boolean(createFormik.errors.goalID)
              }
              helperText={
                createFormik.touched.goalID && createFormik.errors.goalID
              }
              value={createFormik.values.goalID}
              onChange={createFormik.handleChange}
              onBlur={createFormik.handleBlur}
              type="text"
              size="small"
              fullWidth
              margin="dense"
              label={translate("nudges_goal")}
            >
              {goalRows !== null
                ? goalRows.map((goal) => {
                    return (
                      <MenuItem key={goal.id} value={goal.id}>
                        {goal.name}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
            <InputLabel style={{ marginTop: 10, marginBottom: 5 }}>
              GIF
            </InputLabel>
            <FileUploader
              name="file"
              type="file"
              preview={createFormik.values.image}
              updateFilesCallback={updateUploadedFiles}
              buttontitle="Upload File"
            />
            <InputLabel
              style={{
                marginTop: 10,
                marginBottom: 5,
                paddingTop: 10,
                borderTop: "1px solid rgba(0, 0, 0, 0.24)",
              }}
            >
              Preview Image
            </InputLabel>
            <FileUploader
              name="preview_img_file"
              type="file"
              preview={createFormik.values.previewImage}
              updateFilesCallback={updateUploadedFiles}
              buttontitle="Upload File"
            />
            <DialogActions>
              <Button variant="contained" type="submit" color="primary">
                {translate("label_save")}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
      <Snackbar
        open={notification.active}
        autoHideDuration={5000}
        onClose={closeNotification}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert variant="filled" severity={notification.type}>
          {notification.content}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
