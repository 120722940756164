import {
    getNudges,
} from '../../api/nudges';

export function nudges(params) {
    return async function nudgesThunk(dispatch) {
        dispatch({type: 'nudges/loadNudges'});

        try {
            const response = await getNudges(params);
            dispatch({type: 'nudges/nudgesLoaded', payload: response.data});
        } catch (e) {
            dispatch({type: 'nudges/loadNudgesFailed', payload: e.message});
        }
    }
}