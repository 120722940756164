import { useRef, useEffect, useCallback } from "react";
import throttle from "lodash/throttle";

function useThrottle(cb, delay) {
    const options = { leading: false, trailing: true };
    const cbRef = useRef(cb);
  
    useEffect(() => { 
        cbRef.current = cb;
    });
    
    //eslint-disable-next-line
    return useCallback(
        throttle((...args) => cbRef.current(...args), delay, options),
        [delay]
    );
}

export default useThrottle;