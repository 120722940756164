import jwt from 'jwt-decode';
import { refreshToken } from '../api/account';

const safetyTimespan = 120; // 2minutes
let refreshTokenTimer = null;

const refresh = async (token) => {
    try {
        const response = await refreshToken(token);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
    } catch (e) {
        console.error(e);
    }
}

export default function scheduleTokenRefresh() {
    const refreshToken = localStorage.getItem('refreshToken');
    const token = localStorage.getItem('accessToken');
    const decodedToken = jwt(token);
    const expiresInSeconds = decodedToken.exp - Math.floor(Date.now() / 1000);

    if (refreshTokenTimer) {
        clearTimeout(refreshTokenTimer);
        refreshTokenTimer = null;
    }

    // If token already expired, do not run the refresh
    if (expiresInSeconds - safetyTimespan < 0) {
        return;
    }

    // Schedule timer 2 minutes before it extends
    refreshTokenTimer = setTimeout(async () => {
        await refresh(refreshToken);
        // Schedule next refresh
        scheduleTokenRefresh();
    }, (expiresInSeconds - safetyTimespan) * 1000)
} 