import {
    getEvents,
} from '../../api/session-reports';

export function events(params) {
    return async function eventsThunk(dispatch) {
        dispatch({type: 'sessionReports/loadEvents'});

        try {
            const response = await getEvents(params);
            dispatch({type: 'sessionReports/eventsLoaded', payload: response.data});
        } catch (e) {
            dispatch({type: 'sessionReports/loadEventsFailed', payload: e.message});
        }
    }
}