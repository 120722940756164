import {
    getGoals,
} from '../../api/goals';

export function goals(params) {
    return async function goalsThunk(dispatch) {
        dispatch({type: 'goals/loadGoals'});

        try {
            const response = await getGoals(params);
            dispatch({type: 'goals/goalsLoaded', payload: response.data});
        } catch (e) {
            dispatch({type: 'goals/loadGoalsFailed', payload: e.message});
        }
    }
}