import reqEnum from '../../utils/request-statuses';

const initialState = {
    loggedIn: false,
    status: null,
    profile: null,
    error: '',
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case "auth/login":
            return {
                ...state,
                error: '',
                status: reqEnum.loading,
            };
        case "auth/loggedIn":
            return {
                ...state,
                error: '',
                status: reqEnum.fullfilled,
                loggedIn: true,
            };
        case "auth/loginFailed":
            return {
                ...state,
                error: action.payload,
                status: reqEnum.rejected,
            };
        case "auth/loadProfile":
            return {
                ...state,
                status: reqEnum.loading,
            };
        case "auth/profileLoaded":
            return {
                ...state,
                status: reqEnum.fullfilled,
                profile: action.payload.user,
                loggedIn: true,
            };
        case "auth/loadProfileFailed":
            return {
                ...state,
                status: reqEnum.rejected,
            };
        case "auth/loggedOut":
            return initialState;
        case "auth/logoutFailed":
            return {
                ...state,
                error: action.payload,
                status: reqEnum.rejected,
            }
        default:
            return state;
    }
}