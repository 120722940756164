import {
    getTags,
} from '../../api/tags';

export function tags() {
    return async function tagsThunk(dispatch) {
        dispatch({type: 'sessionReports/loadTags'});

        try {
            const response = await getTags();
            dispatch({type: 'sessionReports/tagsLoaded', payload: response.data});
        } catch (e) {
            dispatch({type: 'sessionReports/loadTagsFailed', payload: e.message});
        }
    }
}