import reqEnum from '../../utils/request-statuses';

const initialState = {
    status: null,
    goals: null,
    pager: {
        size: 20,
        page: 1,
        totalEntries: 0,
    },
    error: '',
};

export default function goalsReducer(state = initialState, action) {
    switch (action.type) {
        case 'goals/loadGoals':
            return {
                ...state,
                status: reqEnum.loading
            };
        case 'goals/goalsLoaded':
            const {
                paginator,
            } = action.payload;
            const pager = {
                totalEntries: paginator.total_entries_size,
                currentEntries: paginator.current_entries_size,
                page: paginator.page,
                size: paginator.per_page,
            };
            return {
                ...state,
                status: reqEnum.fullfilled,
                error: '',
                goals: action.payload.results,
                pager: pager,
            };
        case 'goals/loadGoalsFailed':
            return {
                ...state,
                status: reqEnum.rejected,
                error: action.payload,
            };
        default:
            return state;
    }
}