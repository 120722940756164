import axios from "axios";
import promise from "promise";

const instance = axios.create({
  timeout: 60000,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return promise.reject(error);
  }
);

instance.objectToQueryParams = (obj) => {
  if (!obj) return "";
  const searchParams = new URLSearchParams();
  Object.keys(obj).forEach((key) => searchParams.append(key, obj[key]));
  return searchParams.toString();
};

export default instance;
