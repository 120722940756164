import React, {
    useEffect,
    useState,
} from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    TableFooter,
    Toolbar,
    Container,
    IconButton,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    Chip,
    CircularProgress,
    TextField,
    Box,
    Fab,
    Snackbar,
    LinearProgress,
    Breadcrumbs,
    Typography,
    Link,
    Grid,
    Tooltip,
    FormControlLabel,
    Checkbox,
    InputAdornment,
} from '@material-ui/core';
import {
    Autocomplete,
    Alert,
} from '@material-ui/lab';
import {
    Replay,
    Print,
    CloudDownload,
    Search,
} from '@material-ui/icons';
import {
    DateTimePicker
} from '@material-ui/pickers';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    useHistory,
} from 'react-router-dom';
import { saveAs } from 'file-saver';
import useThrottle from '../../hooks/useThrottle';
import style from './style.module.scss';
import useTranslate from '../../hooks/useTranslate';
import { events } from '../../store/session-reports/actionCreators';
import { tags } from '../../store/tags/actionCreators';
import { users } from '../../store/users/actionCreator';
import { getTags } from '../../store/tags/selectors';
import {
    getUsers,
    usersLoadingInProgress,
} from '../../store/users/selector';
import {
    getEvents,
    getPager,
    eventsLoadingInProgress,
} from '../../store/session-reports/selectors';
import {
    createReport,
    getEventById,
} from '../../api/session-reports';
import {
    getUsersByID,
} from '../../api/users';

function SessionReports() {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const history = useHistory();
    const tagFilters = useSelector(getTags);
    const rows = useSelector(getEvents);
    const pager = useSelector(getPager);
    const usersLoading = useSelector(usersLoadingInProgress);
    const eventsLoading = useSelector(eventsLoadingInProgress)
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [tagFilter, setTagFilter] = useState('');
    const [sessionLength, setSessionLength] = useState('');
    const [open, setOpen] = useState(false);
    const [totalEnergy, setTotalEnergy] = useState(0);
    const [highestIncrease, setHighestIncrease] = useState(0);
    const [longestFlow, setLongestFlow] = useState(0);
    const [sessionInterrupted, setSessionInterrupted] = useState(false);
    const [wasCalibrated, setCalibrationStatus] = useState(false);
    const [userFilterValue, setUserFilterValue] = useState('');
    const [hwIdFilterValue, setHwIdFilterValue] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [creationInProgress, setCreationStatus] = useState(false);
    const [downloadInProgress, setDownloadProgress] = useState(false);
    const [notification, setNotification] = useState({
        active: false,
        type: '',
        content: '',
    });
    const [tooltipUser, setTooltipUser] = useState({
        userID: null,
        data: {},
    });
    const filteredUsers = useSelector(getUsers);
    const loading = open && usersLoading;
    const throttledGetUsers = useThrottle(() => dispatch(users({
        filter: userFilterValue,
    })), 200);
    const fetchEvents = async (pageSize, pageNum) => {
        const params = {
            timeFrom: dateFrom,
            timeTo: dateTo,
            tagID: tagFilter,
            hwID: hwIdFilterValue,
            per_page: pageSize || pager.size,
            page: pageNum || pager.page,
            userID: selectedUser?.id,
            totalEnergy,
            highestIncrease,
            longestFlow,
        };
        console.log('params: ', params)
        console.log('hwID: ', hwIdFilterValue)
        if (sessionLength) {
            params.sessionLength = sessionLength;
        }
        if (sessionInterrupted) {
            params.sessionInterrupted = sessionInterrupted;
        }
        if (wasCalibrated) {
            params.wasCalibrated = wasCalibrated;
        }
        await dispatch(events(params));
    };
    const throttletGetEvents = useThrottle(() => fetchEvents(null, 1), 300);
    const columns = [
        { id: 'ID', label: translate('reports_table_session_id') },
        { id: 'userID', label: translate('reports_table_user_id') },
        { id: 'sessionTaggedEvents', label: translate('reports_table_session_events') },
        { id: 'hwId', label: translate('reports_table_hw_id') },
        { id: 'createdAt', label: translate('reports_table_created_at') },
        { id: 'download', label: translate('reports_download_log'), align: "center" },
    ];
    const generateReport = async () => {
        setCreationStatus(true);
        try {
            await createReport({
                timeFrom: dateFrom,
                timeTo: dateTo,
                tagID: tagFilter,
                userID: selectedUser?.id
            });
            setNotification({
                active: true,
                type: 'success',
                content: translate('reports_session_log_report_created'),
            });
        } catch (e) {
            setNotification({
                active: true,
                type: 'error',
                content: translate(e.message),
            });
        } finally {
            setCreationStatus(false);
        }
    };
    const closeNotification = () => {
        setNotification({
            active: false,
        });
    };
    const displayUserDetails = async (id) => {
        // Optimize so you dont fetch already fetched user
        if (id === tooltipUser.id) return;

        try {
            const response = await getUsersByID(id);
            setTooltipUser({
                id: id,
                data: response.data,
            })
        } catch (e) {
            // Just print err to console
            console.log(e.message);
        }
    }
    const downloadLog = async (row) => {
        if (downloadInProgress) {
            return;
        }

        setDownloadProgress(true);
        try {
            const result = await getEventById(row.id);

            var today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + 'T' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
            const file = new File([JSON.stringify(result.data)], `${row.userID}-${date}.json`, { type: "application/json;charset=utf-8" });
            saveAs(file);
        } catch (e) {
            setNotification({
                active: true,
                type: 'error',
                content: translate(e.message),
            });
        } finally {
            setDownloadProgress(false);
        }
    };

    useEffect(() => {
        throttletGetEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, dateTo, tagFilter, hwIdFilterValue, totalEnergy, highestIncrease, longestFlow, sessionLength, sessionInterrupted, wasCalibrated, selectedUser?.id, dispatch]);

    useEffect(() => {
        dispatch(tags());
    }, [dispatch]);

    useEffect(() => {
        throttledGetUsers()
    }, [userFilterValue]); // eslint-disable-line

    return (
        <React.Fragment>
            {(eventsLoading || downloadInProgress) &&
                <LinearProgress />
            }
            <Container>
                <Box py={2}>
                    <Breadcrumbs>
                        <Link color="inherit" to="/" onClick={() => history.push(`/`)}>
                            <Typography color="textPrimary">{translate("breadcrumbs_home")}</Typography>
                        </Link>
                        <Typography color="textPrimary">{translate("breadcrumbs_session_logs")}</Typography>
                    </Breadcrumbs>
                </Box>
            </Container>
            <Container className={style.homeContainer}>
                <Toolbar>
                    <Box px={1}>
                        <DateTimePicker
                            label={translate("label_datepicker_from")}
                            disableFuture={true}
                            value={dateFrom}
                            onChange={setDateFrom}
                        />
                    </Box>
                    <Box px={1}>
                        <DateTimePicker
                            label={translate("label_datepicker_to")}
                            disableFuture={true}
                            value={dateTo}
                            onChange={setDateTo}
                        />
                    </Box>
                    <Box ml={"auto"}>
                        <IconButton onClick={() => fetchEvents()}
                            disabled={eventsLoading}>
                            <Replay />
                        </IconButton>
                    </Box>
                </Toolbar>
                <Toolbar>
                    <Grid container>
                        <Box px={1} marginRight={5}>
                            <FormControl>
                                <InputLabel id="tags">{translate("label_tags")}</InputLabel>
                                <Select
                                    labelId="tags"
                                    autoWidth={true}
                                    value={tagFilter}
                                    onChange={(e) => setTagFilter(e.target.value)}
                                    style={{ minWidth: 120 }}
                                >
                                    <MenuItem key={0} value="">&nbsp;</MenuItem>
                                    {
                                        tagFilters.map((el) => {
                                            return <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box px={1} marginRight={5}>
                            <Autocomplete
                                style={{ minWidth: 240 }}
                                open={open}
                                onInputChange={(e, value) => setUserFilterValue(value)}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                onChange={(e, value) => setSelectedUser(value)}
                                options={filteredUsers}
                                getOptionLabel={(option) => {
                                    if (!option.email) return userFilterValue;
                                    return `(ID:${option.id})${option.email}`;
                                }}
                                getOptionSelected={(option, value) => option.email === value}
                                loading={loading}
                                value={userFilterValue}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={translate("label_user")}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        <Box px={1} marginRight={5}>
                            <FormControl>
                                <InputLabel id="sessionLength">{translate("label_session_length")}</InputLabel>
                                <Select
                                    labelId="sessionLength"
                                    autoWidth={true}
                                    value={sessionLength}
                                    onChange={(e) => setSessionLength(e.target.value)}
                                    style={{ minWidth: 140 }}
                                >
                                    <MenuItem key={0} value="">&nbsp;</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box px={1}>
                            <TextField
                                type="search"
                                margin="none"
                                label={translate("label_search_hw_mac_addr")}
                                onChange={(e) => { setHwIdFilterValue(e.target.value) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>

                    </Grid>
                </Toolbar>
                <Toolbar>
                    <Box px={1} marginRight={5}>
                        <TextField
                            label={translate("label_total_energy")}
                            value={totalEnergy}
                            onChange={(e) => { setTotalEnergy(e.target.value); }}
                            inputProps={{
                                min: 0
                            }}
                            type="number" />
                    </Box>
                    <Box px={1} marginRight={5}>
                        <TextField
                            label={translate("label_highest_increase")}
                            value={highestIncrease}
                            onChange={(e) => { setHighestIncrease(e.target.value); }}
                            inputProps={{
                                min: 0
                            }}
                            type="number" />
                    </Box>
                    <Box px={1} marginRight={5}>
                        <TextField
                            label={translate("label_longest_flow")}
                            value={longestFlow}
                            onChange={(e) => { setLongestFlow(e.target.value); }}
                            inputProps={{
                                min: 0
                            }}
                            type="number" />
                    </Box>
                </Toolbar>
                <Toolbar>
                    <Box px={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sessionInterrupted}
                                    onChange={(e) => { setSessionInterrupted(e.target.checked); }}
                                    name="sessionInterrupted"
                                />
                            }
                            label={translate("label_session_interrupted")}
                        />
                    </Box>
                    <Box px={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={wasCalibrated}
                                    onChange={(e) => { setCalibrationStatus(e.target.checked); }}
                                    name="wasCalibrated"
                                />
                            }
                            label={translate("label_has_calibration_events")}
                        />
                    </Box>
                </Toolbar>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map((column) => {
                                        return (
                                            <TableCell key={column.id}
                                                align={column.align || 'inherit'}>
                                                {column.label}
                                            </TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!rows) && (
                                <TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        {translate("table_empty_data_set")}
                                    </TableCell>
                                </TableRow>)
                            }
                            {rows && rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        {row.id}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip placement="left"
                                            onOpen={() => displayUserDetails(row.userID)}
                                            title={<React.Fragment>{row.userID !== tooltipUser.id ?
                                                <CircularProgress size={12} style={{ color: "#fff" }} /> :
                                                tooltipUser.data?.email
                                            }
                                            </React.Fragment>}>
                                            <Typography>{row.userID}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{
                                        row.sessionTaggedEvents.map((el) => {
                                            return (
                                                <Chip key={el.tagID}
                                                    size="small"
                                                    label={el.tagName} />
                                            )
                                        })
                                    }</TableCell>
                                    <TableCell>{row.sessionRecord.hwID}</TableCell>
                                    <TableCell>{row.createdAt}</TableCell>
                                    <TableCell align="center">
                                        <CloudDownload
                                            onClick={() => downloadLog(row)} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell
                                    style={{ borderBottom: 'none' }}
                                    colSpan="4">
                                    {pager?.totalEntries >= 500 && (
                                        <span>{translate('reports_table_pager_disclaimer')}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    style={{ borderBottom: 'none' }}>
                                    {pager && (
                                        <TablePagination
                                            rowsPerPageOptions={[10, 20, 50]}
                                            component="div"
                                            count={pager.totalEntries}
                                            rowsPerPage={pager.size}
                                            page={pager.page - 1}
                                            onChangePage={(e, value) => fetchEvents(null, value + 1)}
                                            onChangeRowsPerPage={(e, value) => fetchEvents(e.target.value, null)}
                                        >
                                            {pager.totalEntries >= 5 && (
                                                <span>More than 500</span>
                                            )}
                                        </TablePagination>
                                    )
                                    }
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Fab variant="extended"
                    disabled={creationInProgress || (!rows || rows.length < 1)}
                    className={style.generateReportFab}
                    onClick={generateReport}
                    style={{ position: "fixed" }}>
                    <Print />
                    {translate("reports_generate_report")}
                    {creationInProgress && <CircularProgress className={style.fabLoader} size={24} />}
                </Fab>
                <Snackbar open={notification.active}
                    autoHideDuration={5000}
                    onClose={closeNotification}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert variant="filled" severity={notification.type}>
                        {notification.content}
                    </Alert>
                </Snackbar>
            </Container>
        </React.Fragment>
    );
}

export default SessionReports;