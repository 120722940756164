import reqEnum from '../../utils/request-statuses';

const initialState = {
    status: null,
    list: [],
    error: '',
};

export default function tagsReducer(state = initialState, action) {
    switch (action.type) {
        case 'users/loadUsers':
            return {
                ...state,
                status: reqEnum.loading
            };
        case 'users/usersLoaded':
            return {
                ...state,
                status: reqEnum.fullfilled,
                error: '',
                list: action.payload.results,
            };
        case 'users/loadUsersFailed':
            return {
                ...state,
                status: reqEnum.rejected,
                error: action.payload,
            };
        default:
            return state;
    }
}