import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette:{
        primary: {
            main: '#e88a61',
        },
        secondary: {
            main: '#518B88',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#ff9800',
        },
        info: {
            main: '#2196f3',
        },
        success: {
            main: '#00afb9',
        },
        background: {
            default: '#f4f3ef'
        }
    },
    spacing: 4,
});

export default theme;