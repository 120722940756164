const initialState = {
    lang: 'en',
    keys: {
        en: {
            // Domain errors
            'login_email': 'E-Mail',
            'login_login': 'Login',
            'login_password': 'Password',
            'login_forgot_password': 'Forgot password?',
            'login_forgot_pass_title': 'Request new password',
            'login_new_passwrod_requested': 'New password requested',
            'login_forgot_password_request': 'Request new',
            'accept_invite_password': 'Password',
            'accept_invite_repeat_password': 'Repeat password',
            'accept_invite_accept': 'Accept',
            'accept_invite_accept_success': 'You registered succesfully. You will be redirected to Login page.',
            'reports_table_session_id': 'Session ID',
            'reports_table_user_id': 'User ID',
            'reports_table_created_at': 'Created at',
            'reports_table_session_events': 'Session events',
            'reports_table_hw_id': 'HW ID',
            'reports_get_events': 'Get Events',
            'reports_generate_report': 'Request report',
            'reports_download_log': 'Download log',
            'reports_table_pager_disclaimer': '*More than 500 results match your search criteria',
            'reports_session_log_report_created': 'Your report is on its way and it will be delivered to your mail. It may take a few minutes to generate the report.',
            'reports_hw_logs_report_created': 'Your report is on its way and it will be delivered to your mail. It may take a few minutes to generate the report.',
            'home_reports': 'Reports',
            'home_reports_description': 'Inspect detailed reports',
            'home_reports_session_logs': 'Session logs',
            'home_firmware': 'Device Firmware',
            'hardware_version': 'Hardware Version',
            'hardware_flavor': 'Hardware Flavor',
            'firmwares_new_firmware': 'Upload Device Firmware',
            'firmwares_firmware_created': 'New Firmare created',
            'firmwares_table_fw_version': 'Firmware Version',
            'firmwares_table_hw_version': 'Hardware Version',
            'firmwares_table_download_link': 'Download URL',
            'home_firmware_description': 'Manage Mendi device Firmware',
            'home_firmware_upload': 'Firmware upload',
            'home_accounts': 'Accounts',
            'home_accounts_description': 'Inspect Mendi users',
            'home_accounts_users': 'Users grid',
            'home_feature_flags': 'Feature flags',
            'home_feature_flags_description': 'Used for A/B testing',
            'home_feature_flags_list': 'List of flags',
            'home_hw_test_logs': 'Hardware logs',
            'home_hw_test_logs_description': 'Logs for hardware QA production tests',
            'home_hw_test_logs_pcba': 'PCBA logs',
            'home_hw_test_logs_functional': 'Functional logs',
            'home_nudges': 'Nudges',
            'home_nudges_description': 'Create and manage nudges',
            'breadcrumbs_home': 'Home',
            'breadcrumbs_session_logs': 'Session logs',
            'breadcrumbs_feature_flags': 'Feature flags',
            'breadcrumbs_hw_logs': 'HW Logs',
            'breadcrumbs_hw_logs_pcba': 'PCBA test',
            'breadcrumbs_hw_logs_functional': 'Functional test',
            'breadcrumbs_nudges': 'Nudges',
            'breadcrumbs_firmwares': 'Device Firmwares',
            'flags_table_key': 'Key',
            'flags_table_distribution': 'Distribution (%)',
            'flags_table_description': 'Description',
            'flags_table_created_at': 'Created at',
            'flags_new_flag_title': 'Create new flag',
            'flags_delete_flag_title': 'Delete flag',
            'flags_key': 'Key',
            'flags_distribution': 'Distribution',
            'flags_description': 'Description',
            'flags_new_flag_created': 'Flag created',
            'flags_new_flag_updated': 'Flag updated',
            'flags_table_edit': 'Edit',
            'flags_table_delete': 'Delete',
            'flags_confirm_delete': 'Are you sure you want to delete flag',
            'flags_confirm_delete_note': 'This actions is irreversible',
            'flags_flag_deleted': 'Flag deleted',
            'flags_edit_flag_title': 'Edit flag',
            'logs_table_id': 'Log ID',
            'logs_table__mac_addr': 'HW MAC address',
            'logs_table__boxid': 'HW Box ID',
            'logs_table_created_at': 'Created at',
            'logs_table_details': 'Full log',
            'logs_full_details': 'Inspect details',
            // General errors
            'general_error': 'Something went wrong',
            'validation_required': 'Required',
            'validation_password_mismatch': 'Password do not match',
            'validation_value_too_low': 'Value too low',
            'validation_value_too_big': 'Value too big',
            'validation_invalid_mail': 'Invalid mail address',
            'label_datepicker_from': 'From',
            'label_datepicker_to': 'To',
            'label_tags': 'Tags',
            'label_user': 'User',
            'label_save': 'Save',
            'label_upload': 'Upload',
            'label_close': 'Close',
            'label_logout': 'Logout',
            'label_confirm': 'Confrim',
            'label_total_energy': 'Energy larger than',
            'label_longest_flow': 'Flow larger than',
            'label_session_length': 'Session length',
            'label_highest_increase': 'Increase larger than',
            'label_session_interrupted': 'Session was interrupted',
            'label_has_calibration_events': 'Has calibration events',
            'label_search_hw_mac_addr': 'HW Mac address',
            'label_search_boxid': 'HW Box ID',
            'table_empty_data_set': 'There is no data matching your criteria',
            'select_reset_option': 'Reset tag',
            // API errors
            'unable_to_login': 'Unable to login',
            'unable_to_accept_invitation': 'Unable to accept invite',
            'reset_pass_success': 'Password resetted succesfully',
            'reset_pass_password': 'Password',
            'reset_pass_repeat_password': 'Repeat password',
            'reset_pass_reset': 'Reset',
            'password_requirements_failed': 'Passwords needs to be min 8 characters long. It must include at least on symbol, on number and one uppercase letter',
            'nudges_table_title': 'Title',
            'nudges_table_small_title': 'Small Title',
            'nudges_table_paragraph_one': 'First Paragraph',
            'nudges_table_paragraph_two': 'Second Paragraph',
            'nudges_table_active': 'Active',
            'nudges_table_image': 'GIF',
            'nudges_table_preview_image': 'Preview Image',
            'nudges_table_goal_id': 'GoalID',
            'nudges_new_nudge': 'Create new nudge',
            'nudges_title': 'Title',
            'nudges_small_title': 'Small Title',
            'nudges_paragraph_one': 'First Paragraph',
            'nudges_paragraph_two': 'Second Paragraph',
            'nudges_goal': 'Goal',
            'nudges_nudge_created': 'Nudge created',
            'nudges_delete_flag_title': 'Delete nudge?',
            'nudges_confirm_delete': 'Are you sure you want to delete nudge',
            'nudges_nudge_updated': 'Nudge updated',
            'nudges_nudge_deleted': 'Nudge deleted',
            'nudges_edit_nudge_title': 'Edit nudge',
            'firmwares_delete_flag_title': 'Delete firmware?',
            'firmwares_confirm_delete': 'Are you sure you want to delete firmware',
            'firmwares_firmware_deleted': 'Firmware deleted',
        },
    },
};

export default function translateReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}