import React from "react";
import {
    Container,
    Grid,
    Box,
    Chip,
    Card,
    CardHeader,
    CardContent,
} from "@material-ui/core";
import {
    FileCopy,
    Backup,
    AccountCircle,
    Assessment,
    Settings,
    GridOn,
    Flag,
    ViewListOutlined,
    Tune,
    Code,
    Repeat,
    Info,
} from "@material-ui/icons";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";
import Header from "../../components/Header/Header";
import SessionReports from "../SessionReports/SessionReports";
import FeatureFlags from "../FeatureFlags/FeatureFlags";
import HwLogs from "../HwLogs/HwLogs";
import Nudges from "../Nudges/Nudges";
import Firmwares from "../Firmwares/Firmwares";
import style from "./style.module.scss";
import useTranslate from "../../hooks/useTranslate";

function Home() {
    const history = useHistory();
    let { path, url } = useRouteMatch();
    const translate = useTranslate();

    return (
        <React.Fragment>
            <Header />
            <Switch>
                <Route exact path={`${path}feature-flags`}>
                    <FeatureFlags />
                </Route>
                <Route exact path={`${path}session-reports`}>
                    <SessionReports />
                </Route>
                <Route path={`${path}hw-logs/:logType`}>
                    <HwLogs />
                </Route>
                <Route path={`${path}nudges`}>
                    <Nudges />
                </Route>
                <Route path={`${path}firmwares`}>
                    <Firmwares />
                </Route>
                <Route exact path={path}>
                    <Container className={style.homeContainer}>
                        <Grid container p={1} direction="row">
                            <Box m={0} p={1} className={style.moduleSection}>
                                <Card style={{ width: "100%" }} raised>
                                    <CardHeader
                                        avatar={<Assessment fontSize="large" />}
                                        title={translate("home_reports")}
                                        subheader={translate("home_reports_description")}
                                    ></CardHeader>
                                    <CardContent>
                                        <Chip
                                            icon={<FileCopy />}
                                            onClick={() => history.push(`${url}session-reports`)}
                                            label={translate("home_reports_session_logs")}
                                            clickable
                                            color="primary"
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box m={0} p={1} className={style.moduleSection}>
                                <Card style={{ width: "100%" }} raised>
                                    <CardHeader
                                        avatar={<Flag fontSize="large" />}
                                        title={translate("home_feature_flags")}
                                        subheader={translate("home_feature_flags_description")}
                                    ></CardHeader>
                                    <CardContent>
                                        <Chip
                                            icon={<ViewListOutlined />}
                                            onClick={() => history.push(`${url}feature-flags`)}
                                            label={translate("home_feature_flags_list")}
                                            clickable
                                            color="primary"
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box m={0} p={1} className={style.moduleSection}>
                                <Card style={{ width: "100%" }} raised>
                                    <CardHeader
                                        avatar={<Tune fontSize="large" />}
                                        title={translate("home_hw_test_logs")}
                                        subheader={translate("home_hw_test_logs_description")}
                                    ></CardHeader>
                                    <CardContent>
                                        <Grid container direction="row">
                                            <Box mr={1}>
                                                <Chip
                                                    icon={<Code />}
                                                    onClick={() => history.push(`${url}hw-logs/pcba`)}
                                                    label={translate("home_hw_test_logs_pcba")}
                                                    clickable
                                                    color="primary"
                                                />
                                            </Box>
                                            <Box mr={1}>
                                                <Chip
                                                    icon={<Code />}
                                                    onClick={() =>
                                                        history.push(`${url}hw-logs/functional`)
                                                    }
                                                    label={translate("home_hw_test_logs_functional")}
                                                    clickable
                                                    color="primary"
                                                />
                                            </Box>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box m={0} p={1} className={style.moduleSection}>
                                <Card style={{ width: "100%" }} raised>
                                    <CardHeader
                                        avatar={<Repeat fontSize="large" />}
                                        title={translate("home_nudges")}
                                        subheader={translate("home_nudges_description")}
                                    ></CardHeader>
                                    <CardContent>
                                        <Grid container direction="row">
                                            <Box mr={1}>
                                                <Chip
                                                    icon={<Info />}
                                                    onClick={() => history.push(`${url}nudges`)}
                                                    label={translate("home_nudges")}
                                                    clickable
                                                    color="primary"
                                                />
                                            </Box>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box m={0} p={1} className={`${style.moduleSection} disabled`}>
                                <Card style={{ width: "100%" }} raised>
                                    <CardHeader
                                        avatar={<AccountCircle fontSize="large" />}
                                        title={translate("home_accounts")}
                                        subheader={translate("home_accounts_description")}
                                    ></CardHeader>
                                    <CardContent>
                                        <Chip
                                            icon={<GridOn />}
                                            label={translate("home_accounts_users")}
                                            clickable
                                            color="secondary"
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box m={0} p={1} className={`${style.moduleSection}`}>
                                <Card style={{ width: "100%" }} raised>
                                    <CardHeader
                                        avatar={<Settings fontSize="large" />}
                                        title={translate("home_firmware")}
                                        subheader={translate("home_firmware_description")}
                                    ></CardHeader>
                                    <CardContent>
                                        <Chip
                                            icon={<Backup />}
                                            onClick={() => history.push(`${url}firmwares`)}
                                            label={translate("home_firmware_upload")}
                                            clickable
                                            color="secondary"
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </Container>
                </Route>
                <Route path="*">Page Not Found - Apply custom design</Route>
            </Switch>
        </React.Fragment>
    );
}

export default Home;