import React, {
    useEffect,
    useState,
} from 'react';
import {
    Container,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Breadcrumbs,
    Box,
    Typography,
    Link,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Snackbar,
    CircularProgress,
} from '@material-ui/core';
import {
    Alert,
} from '@material-ui/lab';
import {
    Add,
    Edit,
    Delete,
} from '@material-ui/icons';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    useHistory,
} from 'react-router-dom';
import { 
    useFormik,
} from 'formik';
import * as Yup from 'yup';
import { flags } from '../../store/feature-flags/actionCreators';
import {
    getFlags,
} from '../../store/feature-flags/selectors';
import useTranslate from '../../hooks/useTranslate';
import {
    createFlag,
    deleteFlag,
    editFlag,
} from '../../api/feature-flags';
import style from './style.module.scss';

function FeatureFlags() {
    const dispatch = useDispatch();
    const history = useHistory();
    const rows = useSelector(getFlags);
    const translate = useTranslate();
    const [createNewDialog, toggleCreateNewDialog] = useState(false);
    const [deleteDialog, toggleDeleteDialog] = useState(false);
    const [editDialog, toggleEditDialog] = useState(false);
    const [flagForDeletion, setFlagForDeletion] = useState(null);
    const [flagForEdit, setFlagForEdit] = useState(null);
    const [notification, setNotification] = useState({
        active: false,
        type: '',
        content: '',
    });
    const [creationInProgress, setCreationStatus] = useState(false);
    const columns = [
        { id: 'key', label: translate('flags_table_key') },
        { id: 'distribution', label: translate('flags_table_distribution') },
        { id: 'description', label: translate('flags_table_description') },
        { id: 'createdAt', label: translate('flags_table_created_at') },
        { id: 'edit', label: translate('flags_table_edit'), align: "center" },
        { id: 'delete', label: translate('flags_table_delete'), align: "center" },
    ];
    const createFormik = useFormik({
        initialValues: {
            key: '',
            distribution: 0,
            description: ''
        },
        validationSchema: Yup.object({
            key: Yup.string().required(translate('validation_required')),
            distribution: Yup.number()
                .min(0, translate('validation_value_too_low'))
                .max(1, translate('validation_value_too_big'))
                .required(translate('validation_required')),
            description: Yup.string().required(translate('validation_required')),
        }),
        onSubmit: async (values) => {
            setCreationStatus(true);
            try {
                await createFlag(values);
                dispatch(flags());
                toggleCreateNewDialog(false);
                setNotification({
                    active: true,
                    type: 'success',
                    content: translate('flags_new_flag_created'),
                });
            } catch (e) {
                setNotification({
                    active: true,
                    type: 'error',
                    content: translate(e.message),
                });
            } finally {
                setCreationStatus(false);
            }
        }
    });
    const editFormik = useFormik({
        initialValues: {
            key: flagForEdit?.key,
            distribution: flagForEdit?.distribution,
            description: flagForEdit?.description,
        },
        validationSchema: Yup.object({
            distribution: Yup.number()
                .min(0, translate('validation_value_too_low'))
                .max(1, translate('validation_value_too_big'))
                .required(translate('validation_required')),
            description: Yup.string().required(translate('validation_required')),
        }),
        onSubmit: async (values) => {
            try {
                await editFlag(flagForEdit.id, values);
                dispatch(flags());
                toggleEditDialog(false);
                setNotification({
                    active: true,
                    type: 'success',
                    content: translate('flags_new_flag_updated'),
                });
            } catch (e) {
                setNotification({
                    active: true,
                    type: 'error',
                    content: translate(e.message),
                });
            }
        }
    });
    const prepareFlagForEdit = (flag) => {
        setFlagForEdit(flag);
        editFormik.initialValues.key = flag.key;
        editFormik.initialValues.description = flag.description;
        editFormik.initialValues.distribution = flag.distribution;
    }
    const closeNotification = () => {
        setNotification({
            active: false,
        });
    };
    const confirmDelete = async () => {
        try {
            await deleteFlag(flagForDeletion.id);
            dispatch(flags());
            setFlagForDeletion(null);
            toggleDeleteDialog(false);
            setNotification({
                active: true,
                type: 'success',
                content: translate('flags_flag_deleted'),
            });
        } catch (e) {
            setNotification({
                active: true,
                type: 'error',
                content: translate(e.message),
            });
        } 
    }

    useEffect(() => {
        dispatch(flags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <Container>
                <Box py={2}>
                    <Breadcrumbs>
                        <Link color="inherit" to="/" onClick={() => history.push(`/`)}>
                            <Typography color="textPrimary">{translate("breadcrumbs_home")}</Typography>
                        </Link>
                        <Typography color="textPrimary">{translate("breadcrumbs_feature_flags")}</Typography>
                    </Breadcrumbs>
                </Box>
            </Container>
            <Container className={style.flagsContainer}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                            {
                                columns.map((column) => {
                                    return (
                                        <TableCell key={column.id}
                                            align={column.align || 'inherit'}>
                                            {column.label}
                                        </TableCell>
                                    );
                                })
                            }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!rows?.length) && (
                            <TableRow>
                                <TableCell align="center" colSpan={6}>
                                    {translate("table_empty_data_set")}
                                </TableCell>
                            </TableRow>)
                            }
                            {rows && rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.key}</TableCell>
                                    <TableCell>{row.distribution * 100}%</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{row.createdAt}</TableCell>
                                    <TableCell align="center">
                                        <Edit 
                                            onClick={() => {
                                                prepareFlagForEdit(row)
                                                toggleEditDialog(true);
                                            }}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Delete
                                            onClick={() => {
                                                setFlagForDeletion(row);
                                                toggleDeleteDialog(true);
                                            }}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog open={deleteDialog}
                    id="deleteFlag"
                    onClose={() => {
                        toggleDeleteDialog(false);
                        setFlagForDeletion(null);
                    }}>
                    <DialogTitle>
                        {translate('flags_delete_flag_title')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {translate('flags_confirm_delete')} <strong>{flagForDeletion?.key}</strong>? {translate('flags_confirm_delete_note')}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                            onClick={confirmDelete}
                            color="primary">
                            {translate('label_confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={createNewDialog}
                    id="createNewFlag"
                    onClose={() => {
                        toggleCreateNewDialog(false);
                        createFormik.resetForm();
                    }}
                    maxWidth="sm"
                    fullWidth
                    disableBackdropClick={creationInProgress}>
                    <DialogTitle>
                        {translate('flags_new_flag_title')}
                    </DialogTitle>
                    <form onSubmit={createFormik.handleSubmit}>
                        <DialogContent>
                            <TextField variant="outlined"
                                error={createFormik.touched.key && Boolean(createFormik.errors.key)}
                                helperText={createFormik.touched.key && createFormik.errors.key}
                                fullWidth
                                name="key"
                                value={createFormik.values.key}
                                onChange={createFormik.handleChange}
                                onBlur={createFormik.handleBlur}
                                type="text"
                                size="small"
                                margin="dense"
                                label={translate('flags_key')}/>
                            <TextField variant="outlined"
                                error={createFormik.touched.distribution && Boolean(createFormik.errors.distribution)}
                                helperText={createFormik.touched.distribution && createFormik.errors.distribution}
                                fullWidth
                                name="distribution"
                                value={createFormik.values.distribution}
                                onChange={createFormik.handleChange}
                                onBlur={createFormik.handleBlur}
                                type="number"
                                size="small"
                                margin="dense"
                                inputProps={{step: 0.01}} 
                                label={translate('flags_distribution')}/>
                            <TextField variant="outlined"
                                error={createFormik.touched.description && Boolean(createFormik.errors.description)}
                                helperText={createFormik.touched.description && createFormik.errors.description}
                                fullWidth
                                name="description"
                                value={createFormik.values.description}
                                onChange={createFormik.handleChange}
                                onBlur={createFormik.handleBlur}
                                multiline
                                rows={4}
                                type="number"
                                size="small"
                                margin="dense"
                                label={translate('flags_description')}/>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit"
                                variant="contained"
                                disabled={createFormik.isSubmitting}>
                                {translate('label_save')}
                                {creationInProgress && <CircularProgress className={style.createLoader} size={24} />}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Dialog open={editDialog}
                    id="editFlag"
                    onClose={() => {
                        toggleEditDialog(false);
                        setFlagForEdit(null);
                    }}
                    maxWidth="sm"
                    fullWidth>
                    <DialogTitle>
                        {translate('flags_edit_flag_title')}
                    </DialogTitle>
                    <form onSubmit={editFormik.handleSubmit}>
                        <DialogContent>
                            <TextField variant="outlined"
                                fullWidth
                                name="key"
                                readOnly
                                disabled
                                value={editFormik.values.key}
                                type="text"
                                size="small"
                                margin="dense"
                                label={translate('flags_key')}/>
                            <TextField variant="outlined"
                                error={editFormik.touched.distribution && Boolean(editFormik.errors.distribution)}
                                helperText={editFormik.touched.distribution && editFormik.errors.distribution}
                                fullWidth
                                name="distribution"
                                value={editFormik.values.distribution}
                                onChange={editFormik.handleChange}
                                onBlur={editFormik.handleBlur}
                                type="number"
                                size="small"
                                margin="dense"
                                inputProps={{step: 0.01}} 
                                label={translate('flags_distribution')}/>
                            <TextField variant="outlined"
                                error={editFormik.touched.description && Boolean(editFormik.errors.description)}
                                helperText={editFormik.touched.description && editFormik.errors.description}
                                fullWidth
                                name="description"
                                value={editFormik.values.description}
                                onChange={editFormik.handleChange}
                                onBlur={editFormik.handleBlur}
                                multiline
                                rows={4}
                                type="number"
                                size="small"
                                margin="dense"
                                label={translate('flags_description')}/> 
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained"
                                type="submit"
                                color="primary">
                                {translate('label_save')}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Fab style={{position: "fixed"}}
                    onClick={() => toggleCreateNewDialog(true)}
                    className={style.createFlagFab}
                    color="primary">
                    <Add />
                </Fab>
                <Snackbar open={notification.active}
                    autoHideDuration={5000}
                    onClose={closeNotification}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert variant="filled" severity={notification.type}>
                        {notification.content}
                    </Alert>
                </Snackbar>
            </Container>
        </React.Fragment>
    )
}

export default FeatureFlags;