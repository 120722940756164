import  React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Toolbar,
    IconButton,
    AppBar,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Snackbar,
} from '@material-ui/core';
import {
    Menu,
    AccountCircle,
    ExitToApp,
} from '@material-ui/icons';
import {
    Alert,
} from '@material-ui/lab';
import style from './style.module.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import useTranslate from '../../hooks/useTranslate';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { getProfile } from '../../store/auth/selectors';
import { logoutUser } from '../../store/auth/actionCreators';

function Header() {
    const dispatch = useDispatch();
    const [drawer, toggleDrawer] = useState(false);
    const profile = useSelector(getProfile);
    const translate = useTranslate();
    const [notification, setNotification] = useState({
        active: false,
        type: '',
        content: '',
    });
    const closeNotification = () => {
        setNotification({
            active: false,
        });
    };
    const logout = () => {
        dispatch(logoutUser())
    }

    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Link to="/" className={style.logoWrapper}>
                        <Logo className={style.logo}/>
                    </Link>
                    <IconButton size="small" color="inherit"
                        onClick={() => toggleDrawer(true)}
                        style={{marginLeft: 'auto'}}>
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer anchor={"right"}
                open={drawer}
                onClose={() => toggleDrawer(false)}>
                <List component="nav"
                    disablePadding
                    className={style.drawerList}>
                    <ListItem divider>
                        <ListItemIcon>
                            <AccountCircle />
                        </ListItemIcon>
                        {profile?.email}
                    </ListItem>
                    <ListItem button
                        onClick={logout}>
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        {translate("label_logout")}
                    </ListItem>
                </List>
            </Drawer>
            <Snackbar open={notification.active}
                autoHideDuration={5000}
                onClose={closeNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert variant="filled" severity={notification.type}>
                    {notification.content}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default Header;