import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Fragment, useState } from "react";

const ThreeDotsMenu = ({ data, onMenuItemClicked }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        id="menu-button"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="card-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            data.active = !data.active;
            onMenuItemClicked(data);
            handleClose();
          }}
        >
          {data.active ? "Disable" : "Enable"}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default ThreeDotsMenu;
