import {
    getFunctionalLogs,
} from '../../api/logs';

export function functionalLogs(params) {
    return async function eventsThunk(dispatch) {
        dispatch({type: 'functionalLogs/loadLogs'});

        try {
            const response = await getFunctionalLogs(params);
            dispatch({type: 'functionalLogs/logsLoaded', payload: response.data});
        } catch (e) {
            dispatch({type: 'functionalLogs/loadLogsFailed', payload: e.message});
        }
    }
}